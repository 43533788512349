
/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, GridApi, ColDef, GridReadyEvent } from 'ag-grid-community';
import { GridService } from 'src/app/shared/services/gridServices/grid.service';

@Component({
  selector: 'fury-detail-cell-renderer-table',
  templateUrl: './detail-cell-renderer-table.component.html'
})
export class DetailCellRendererTable implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  parentGridApi!: GridApi;
  rowId!: string;
  colDefs!: ColDef[];
  defaultColDef!: ColDef;
  rowData!: any[];
  tableHeight: string = '79px';
  isEditing: any;
  masterDetailsGridApi: GridApi;
  editableFields: any = [];

  constructor(private gridService: GridService) { }

  // called on init
  agInit(params: any): void {
    this.params = params;
    this.parentGridApi = params.api;
    this.rowId = params.node.id!;

    this.defaultColDef = {
      flex: 1,
      minWidth: 120,
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      editable: true,
      suppressMenu: true,
    };

    if (params?.api?.componentName && params?.api?.componentName === 'corporateAction' && params?.data?.corporateActionType === 'Ticker Change') {
      let colDefsList = params.api.detailRowColDefs || [];
      // Remove the fractionalQtyDealPrice field object
      this.colDefs = colDefsList.filter(column => column.field !== 'fractionalQtyDealPrice');
      let rowDataList = params.data[params.api.detailRowDataAccessor] || [];
      this.rowData = rowDataList.map(({ fractionalQtyDealPrice, ...rest }) => rest);
    }
    else {
      this.colDefs = params.api.detailRowColDefs || [];
      this.rowData = params.data[params.api.detailRowDataAccessor] || [];
    }
  
  
    this.editableFields = params.api.detailRowEditableFields;
    this.isEditing = params.data.isEditing;
    // set the editable property of the columns based on the isEditing flag
    this.colDefs.forEach((colDef: ColDef) => {
      colDef.editable = this.isEditing && this.editableFields.includes(colDef.field);
    });
    this.tableHeight = this.rowData.length * 42 + 79 + 'px';
    // observe the data changes
    this.gridService.gettaxAllocationData()?.subscribe((data) => {
      if (this.params.data.isEditing) {
        // set the editable property of the columns based on the edit event
        this.colDefs.forEach((colDef: ColDef) => {
          colDef.editable = this.editableFields.includes(colDef.field);
        });
        this.masterDetailsGridApi.setColumnDefs(this.colDefs);

        // set master grid columns to editable
        setTimeout(() => {
          this.updateGridColumns();
        }, 0);
      }
    })
  }

  updateGridColumns() {
    this.masterDetailsGridApi.forEachNode((node: any) => {
      this.masterDetailsGridApi.startEditingCell({
        rowIndex: node.rowIndex,
        colKey: this.colDefs[0].field,
      });
      this.masterDetailsGridApi.refreshCells({
        columns: [this.colDefs[0].field],
        rowNodes: [node],
        force: true
      });
    });
  }

  // called when the cell is refreshed
  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onGridReady(params: GridReadyEvent) {
    var gridInfo = {
      id: this.rowId,
      api: params.api,
    };
    this.masterDetailsGridApi = params.api;
    this.parentGridApi.addDetailGridInfo(this.rowId, gridInfo);
  }

  onFirstDataRendered(params: any) {
    if (this.isEditing) {
      this.startEditing(params);
    }
  }

  startEditing(params: any) {
    params.api.forEachNode((node: any) => {
      params.api.startEditingCell({
        rowIndex: node.rowIndex,
        colKey: 'allocationRatio',
      });
    });
  }

  ngOnDestroy(): void {
    // detail grid is automatically destroyed as it is an Angular component
    this.parentGridApi.removeDetailGridInfo(this.rowId);
  }

  onCellValueChanged(event) {
    // Handle the cell value change in the detail grid
    console.log('Detail cell value changed', event);
    // Propagate changes back to the master grid if necessary
  }
}

/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavService } from '../sidenav.service';
import isFunction from 'lodash-es/isFunction';
import { CommonService } from '../../../../../src/app/shared/services/commonServices/common.service';
import { LIST_FADE_ANIMATION } from '../../../../@fury/shared/list.animation';
import { SettingService } from '../../../views/settingmodule/setting.service';
import { LocalStoreService } from '../../../../../src/app/shared/services/commonServices/local-store.service';
import { HttpClient } from '@angular/common/http';
import { GridService } from '../../../../../src/app/shared/services/gridServices/grid.service';
import { DataService } from '../../../../../src/app/shared/services/commonServices/data.service';
import { ToolbarTrainingVideosService } from '../../toolbar/toolbar-training-videos/toolbar-training-videos.service';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'fury-toolbar-setting',
  templateUrl: './toolbar-setting.component.html',
  styleUrls: ['./toolbar-setting.component.scss'],
  animations: [...LIST_FADE_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarSettingComponent implements OnInit {

  isOpen: boolean;
  subscription1;
  subscription2;
  temp = [];
  lookupTables: any;
  configuration: any;
  createNew: any;
  accountingSetting: any;
  tradeSetting: any;
  portfolioSetting: any;
  userInvestorSetting: any;
  username: any;
  pinedMenu: any;
  ispined: boolean;
  isEnt: any;
  isunpined: boolean;
  public gridData;
  public currentUser: any;
  subComponent: [];
  displayPdfMerge:boolean = false
  @Output() settingPanelValue: EventEmitter<boolean> = new EventEmitter();
  roleAs: string;
  public apiManagement: any;
  trainingVideoCheck: boolean;
  category: string[];
  videoData: any;
  toolbarSetting: any;
  constructor(private sidenavService: SidenavService, private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute, private commonService: CommonService, private settingService: SettingService, private localStore: LocalStoreService,
    private gridService: GridService,
    private dataService: DataService,
    private toolbarTrainingVideosService :ToolbarTrainingVideosService,
    private setuppercentage: SetupPercentageService,
    private translate: TranslateService,

  ) {
    this.gridData = this.gridService.getViewData();
    this.isEnt = JSON.parse(this.localStore.getItem('fss'))['authList'].indexOf('ENTERPRISE_CREATE')
  }


  ngOnInit() {
    this.currentUser = JSON.parse(this.localStore.getItem('fss'));
    this.username = JSON.parse(this.localStore.getItem('fss'))['userName'];
    this.roleAs = JSON.parse(this.localStore.getItem('fss'))['ROLE'];

    this.variableInit();
    this.dataService.data.subscribe(res => {
      if (res && res.settings) {
        this.pinedMenu = res.settings;
        if (this.pinedMenu && this.pinedMenu.length) {
          this.pinedMenu.map(item => {
            this[item['setting']['pivotMode']]?.forEach(element => {
              if (element.component_name == item.settingName) {
                element.ispined = item.status;
                element.id = item.id;
              }
            });
          })
        }
        this.pinTop(null, true);
      }

    })



  }

  pinTop(viewModuleJson?, isIntial?, componentObject?) {
    console.log('componentName', componentObject);
    this.temp = [
      { 'lookupTables': this.lookupTables },
      { 'apiManagement': this.apiManagement },
      { 'configuration': this.configuration },
      { 'createNew': this.createNew },
      { 'accountingSetting': this.accountingSetting },
      { 'tradeSetting': this.tradeSetting },
      { 'portfolioSetting': this.portfolioSetting },
      { 'userInvestorSetting': this.userInvestorSetting },
      {'toolbarSetting':this.toolbarSetting}
    ]
  
    for (let index = 0; index < this.temp.length; index++) {
      if (Object.keys(this.temp[index])[0] == viewModuleJson && !isIntial) {
        let componentIndex = this.temp[index][viewModuleJson].findIndex(x => x.component_name == componentObject.component_name);
        if (this.temp[index][viewModuleJson][componentIndex].ispined == true) {
          this.temp[index][viewModuleJson].splice(0, 0, this.temp[index][viewModuleJson].splice(componentIndex, 1)[0]) 
        }else{
          this.temp[index][viewModuleJson].splice((this.temp[index][viewModuleJson].length - 1), 0, this.temp[index][viewModuleJson].splice(componentIndex, 1)[0]) 
        }
      }else if(isIntial){
        let keys = [];
         this.temp.forEach(el => {
          keys.push(Object.keys(el)[0]);
        }) 
        for (let i = 0; i < this.temp[index][keys[index]].length; i++) {
          if (this.temp[index][keys[index]][i].ispined == true) {
            this.temp[index][keys[index]] = this.moveArrayItemToNewIndex(this.temp[index][keys[index]], i, 0)
          }
        }      
       }
    }
  }
  moveArrayItemToNewIndex(arr, old_index, new_index) {
    
    // if (new_index >= arr.length) {
    //   var k = new_index - arr.length + 1;
    //   while (k--) {
    //     arr.push(undefined);
    //   }
    // }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  variableInit() {
    this.lookupTables = this.settingService.menu['lookupTables']
    this.apiManagement = this.settingService.menu['apiManagement'].filter(element => (this.currentUser.authList.includes(element.authView) || (this.currentUser.authList.includes(element.authCreate) && element.isCreateAuth)))
    this.configuration = this.settingService.menu['configuration']
    this.createNew = this.settingService.menu['createNew']
    this.accountingSetting = this.settingService.menu['accountingSetting']
    this.userInvestorSetting = this.settingService.menu['userInvestorSetting']
    this.toolbarSetting = this.settingService.menu['toolbarSetting']
    this.tradeSetting = this.settingService.menu['tradeSetting']
    this.portfolioSetting = this.settingService.menu['portfolioSetting']
    this.trainingVideoCheck = this.currentUser.authList.indexOf('SEAMLESS_GLOBAL_HELPANDRESOURCE') > -1 ? true : false; 
  }
  addToPin(event, viewFavJson, viewModuleJson) {
    event.stopPropagation();
    if (viewFavJson.ispined != undefined) {
      var reqBody = {
        "id": viewFavJson.id,
        "userName": this.username,
        "view": 'settings',
        "settingName": viewFavJson.component_name,
        "setting": {
          "pivotMode": viewModuleJson,
        },
        status: !viewFavJson.ispined,
      }

      this.http.put('userPerferenceV2/update', reqBody).subscribe(res => {
      })
    } else {
      let reqBody = {
        "userName": this.username,
        "view": 'settings',
        "settingName": viewFavJson.component_name,
        "setting": {
          "pivotMode": viewModuleJson,
        },
        status: true,
      }

      this.http.post('userPerferenceV2/create', reqBody).subscribe(items => {
        viewFavJson.id = items['id']

      })
    }
    this.settingService.menu[viewModuleJson].map(item => {
      if (item.component_name == viewFavJson.component_name && item.ispined) {
        item.ispined = false;
      } else if (item.component_name == viewFavJson.component_name) {
        item.ispined = true;
      }
    })
    this.pinTop(viewModuleJson,false, viewFavJson);
  }
  toggleDropdown() {
    this.commonService.moduleNameObs$.next("Settings");
    // this.isOpen = !this.isOpen;
    // setTimeout(()=>{
    //   this.sidenavService.setCollapsed(this.isOpen);
    // },0)
    this.isOpen = !this.isOpen;
    this.settingPanelValue.emit(this.isOpen);
    //setTimeout(() => {

    this.sidenavService.setCollapsed(this.isOpen);
    //}, 0)
  }


  @HostListener('mouseover')
  onMouseLeave() {
    this.sidenavService.setExpanded(false);
  }
  onClickOutside() {
    if (this.isOpen) {
      this.sidenavService.setCollapsed(false);
    }
    this.isOpen = false;
    this.settingPanelValue.emit(this.isOpen);


  }

  autoplay(event, item?, subCategory?) {
    let videoData = ['Settings', subCategory, item.displayOrder, item.isVideo]
    this.toolbarTrainingVideosService.trainingVideoPlay(event, videoData);
  }

  openPdfMerbe() {
    this.displayPdfMerge = true
  }
  
}

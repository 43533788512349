/*******************************************************************************
 * FORMIDIUM Corp. COPYRIGHT STATEMENT
 *  __________________
 *
 *  2022 - 2023 FORMIDIUM Corp.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Corp.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Corp.
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
 * by visiting www.formidium.com.
 ******************************************************************************/
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { OptPasswordService } from "../../../../../src/app/pages/authentication/otp-password/otp-password.service";
import { AuthService } from "./auth.service";
import { SetupPercentageService } from "src/app/shared/services/commonServices/setup-percentage.service";
import { LocalStoreService } from "./local-store.service";
import { GlobalMessageService } from "src/app/shared/services/commonServices/global-message.service";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { AuthResolver } from "./auth.resolve";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGaurd implements CanActivate {
  public roleAs: string;
  public currentUser: any;
  public loading: boolean;
  public authChunk: any;
  public isprogrees: boolean;
  public isSetup: boolean = false;
  public isComplete: any;
  private isAuthenticated: boolean;
  defaultRouteList = {
    InputRoute: [
      { route: "import/executeWorkflow", auth: "SEAMLESS_INPUT_DIV_IMPORT" },
      { route: "create", auth: "SEAMLESS_INPUT_DIV_CREATE" },
      { route: "upload", auth: "SEAMLESS_INPUT_DIV_UPLOAD" },
      { route: "view", auth: "SEAMLESS_INPUT_DIV_VIEW" },
    ],
    ProcessRoute: [
      {
        route: "automate-process/dailyProcessingView",
        auth: "SEAMLESS_PROCESSING_AUTOMATEPROCESS_CONFIGUREANDEXECUTE",
      },
      {
        route: "automate-process/dailyProcessingStatus",
        auth: "SEAMLESS_PROCESSING_AUTOMATEPROCESS_PROCESSINGSTATUS",
      },
      {
        route: "detailed-workflow",
        auth: "SEAMLESS_PROCESSING_DIV_DETAILEDWORKFLOW",
      },
      { route: "export-view", auth: "SEAMLESS_PROCESSING_DIV_EXPORTVIEW" },
    ],
    CustomizationRoute: [
      { route: "fund-Group", auth: "SEAMLESS_CUSTOMIZATION_DIV_GROUPING" },
      {
        route: "non-custodian-workflow",
        auth: "SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION",
      },
      {
        route: "delivery-process",
        auth: "SEAMLESS_CUSTOMIZATION_DIV_AUTOMATEREPORTDELIVERYLOG",
      },
      {
        route: "report-approval-rule",
        auth: "SEAMLESS_CUSTOMIZATION_DIV_REPORTCONFIGURATION",
      },
      {
        route: "isd-adhoc-configuration",
        auth: "SEAMLESS_ISD_ADHOC_CONFIGURATION_VIEW",
      },
      {
        route: "data-scheduler",
        auth: "SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA",
      },
      {
        route: "ror-config",
        auth: "SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION",
      },
    ],
    UserSetupRoute: [
      { route: "fund-property-list", auth: "SEAMLESS_SETUP_CLIENT_FUND_VIEW" },
      { route: "user-coa-list", auth: "SEAMLESS_CHARTOFACCOUNT_CLIENT_VIEW" },
    ],
    taxationRoute: [
      {
        route: "fund-client-account-list",
        auth: "SEAMLESS_TAXATION_SETUP_FUND_VIEW",
        parentAuth: "SEAMLESS_TAXATION_DIV_SETUP",
      },
      {
        route: "investor-list",
        auth: "SEAMLESS_TAXATION_SETUP_INVESTORS_VIEW",
        parentAuth: "SEAMLESS_TAXATION_DIV_SETUP",
      },
      {
        route: "chart-of-accounts",
        auth: "SEAMLESS_TAXATION_SETUP_COA_VIEW",
        parentAuth: "SEAMLESS_TAXATION_DIV_SETUP",
      },
      {
        route: "config",
        auth: "SEAMLESS_TAXATION_TAXRETURNDETAILS_VIEW",
        parentAuth: "SEAMLESS_TAXATION_DIV_CONFIG_RETURN_PROCESS_TAX",
      },
      {
        route: "calculate-tax",
        auth: "SEAMLESS_TAXATION_TAXALLOCATION_VIEW",
        parentAuth: "SEAMLESS_TAXATION_DIV_CONFIG_RETURN_PROCESS_TAX",
      },
      {
        route: "report1099",
        auth: "SEAMLESS_TAXATION_DIV_REPORTS",
        parentAuth: "SEAMLESS_TAXATION_DIV_REPORTS",
      },
      {
        route: "product-tax-category-classification",
        auth: "SEAMLESS_TAXATION_DIV_REPORTS",
        parentAuth: "SEAMLESS_TAXATION_DIV_REPORTS",
      },
      {
        route: "monthly-columnar-report",
        auth: "SEAMLESS_TAXATION_DIV_REPORTS",
        parentAuth: "SEAMLESS_TAXATION_DIV_REPORTS",
      },
      {
        route: "wash-sale",
        auth: "SEAMLESS_TAXATION_DIV_REPORTS",
        parentAuth: "SEAMLESS_TAXATION_DIV_REPORTS",
      },
      {
        route: "wash-sale-adjusted",
        auth: "SEAMLESS_TAXATION_DIV_REPORTS",
        parentAuth: "SEAMLESS_TAXATION_DIV_REPORTS",
      },
      {
        route: "distribution-report",
        auth: "SEAMLESS_TAXATION_DIV_REPORTS",
        parentAuth: "SEAMLESS_TAXATION_DIV_REPORTS",
      },
      {
        route: "ytd-distribution-base",
        auth: "SEAMLESS_TAXATION_DIV_REPORTS",
        parentAuth: "SEAMLESS_TAXATION_DIV_REPORTS",
      },
      {
        route: "generate",
        auth: "SEAMLESS_TAXATION_DIV_REPORTS",
        parentAuth: "SEAMLESS_TAXATION_DIV_REPORTS",
      },
      {
        route: "generate-tax-reports-new",
        auth: "SEAMLESS_TAXATION_DIV_REPORTS",
        parentAuth: "SEAMLESS_TAXATION_DIV_REPORTS",
      },
    ],
  };
  constructor(
    private router: Router,
    private otpPassword: OptPasswordService,
    private authService: AuthService,
    private setuppercentage: SetupPercentageService,
    private cookieService: CookieService,
    private localstoreservice: LocalStoreService,
    public optPasswordService: OptPasswordService,
    private messageService: GlobalMessageService,
    public translate: TranslateService,
    public AuthResolver: AuthResolver
  ) {
    this.setuppercentage.getMessage().subscribe((message) => {
      this.isComplete = message;
      if (this.isComplete && this.isSetup) {
        this.isprogrees = false;
      } else {
        this.isprogrees = true;
      }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // Use the resolver to check authentication status
    if (this.isAuthenticated) {
      let url: string = state.url;
      let auth = this.checkUserLogin(next, url);
      return of(auth);
    } else {
      return this.AuthResolver.resolve().pipe(
        switchMap((isAuthenticated) => {
          if (isAuthenticated["message"] === "yes") {
            let url: string = state.url;
            let auth = this.checkUserLogin(next, url);
            this.isAuthenticated = true;
            return of(auth);
          }
          // User is not authenticated, store intended route and redirect to login
          this.isAuthenticated = false;
          return of(false);
        })
      );
    }
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    this.currentUser = JSON.parse(this.localstoreservice.getItem("fss"));
    this.authChunk = this.localstoreservice.authFnInGroups(
      this.currentUser?.authList,
      400
    );
    if (this.cookieService.get("access_token") && !this.currentUser?.id) {
      this.getCurrentUser();
    } else if (
      this.cookieService.get("access_token") &&
      this.authService.isLoggedIn1() &&
      this.currentUser &&
      Object.keys(this.currentUser).length
    ) {
      //check session for multitab
      let localId = this.currentUser?.id;
      let sessionUserId = sessionStorage.getItem("userId");
      if (sessionUserId != null && localId != sessionUserId) {
        sessionStorage.clear();
        window.location.reload();
        return false;
      }
      //check if route config has authentication
      if (
        this.localstoreservice.isAuthrised(
          this.authChunk,
          "SEAMLESS_SIDEMENU_SETUP"
        )
      ) {
        this.isSetup = true;
      }
      if (route.data && (route.data.auth || route.data.authsibebar)) {
        let urlAccessAuth = route.data.auth ? false : true;
        let urlAccessAuthSidebar = route.data.authsibebar ? false : true;
        let urlAddOnAuth = route.data.addOnAuth ? false : true;
        let urlCatAuth = route.data.catAuth ? false : true;

        if (
          route.url.length > 1 &&
          route.url[1].path == "dailyProcessingView" &&
          !this.localstoreservice.isAuthrised(
            this.authChunk,
            "SEAMLESS_PROCESSING_AUTOMATEPROCESS_CONFIGUREANDEXECUTE"
          )
        ) {
          for (let item of this.defaultRouteList["ProcessRoute"]) {
            if (this.localstoreservice.isAuthrised(this.authChunk, item.auth)) {
              this.router.navigate(["/rapid-processing/" + item.route]);
              break;
            }
          }
          return false;
        }
        if (
          route.url.length > 1 &&
          route.url[1].path == "executeWorkflow" &&
          !this.localstoreservice.isAuthrised(
            this.authChunk,
            "SEAMLESS_INPUT_DIV_IMPORT"
          )
        ) {
          for (let item of this.defaultRouteList["InputRoute"]) {
            if (this.localstoreservice.isAuthrised(this.authChunk, item.auth)) {
              this.router.navigate(["/input/" + item.route]);
              break;
            }
          }
          return false;
        }
        if (
          route.url.length > 0 &&
          route.url[0].path == "fund-property-list" &&
          !this.localstoreservice.isAuthrised(
            this.authChunk,
            "SEAMLESS_SETUP_CLIENT_FUND_VIEW"
          )
        ) {
          for (let item of this.defaultRouteList["UserSetupRoute"]) {
            if (this.localstoreservice.isAuthrised(this.authChunk, item.auth)) {
              this.router.navigate(["/user-setup/" + item.route]);
              break;
            }
          }
          return false;
        }
        if (
          route.url.length > 0 &&
          route.url[0].path == "fund-Group" &&
          !this.localstoreservice.isAuthrised(
            this.authChunk,
            "SEAMLESS_CUSTOMIZATION_DIV_GROUPING"
          )
        ) {
          for (let item of this.defaultRouteList["CustomizationRoute"]) {
            if (this.localstoreservice.isAuthrised(this.authChunk, item.auth)) {
              this.router.navigate(["/customization/" + item.route]);
              break;
            }
          }
          return false;
        }

        if (
          route.url.length > 0 &&
          route.url[0].path == "fund-client-account-list" &&
          (!this.localstoreservice.isAuthrised(
            this.authChunk,
            "SEAMLESS_TAXATION_DIV_SETUP"
          ) ||
            !this.localstoreservice.isAuthrised(
              this.authChunk,
              "SEAMLESS_TAXATION_SETUP_FUND_VIEW"
            ))
        ) {
          let taxAccess = false;
          for (let item of this.defaultRouteList["taxationRoute"]) {
            if (
              this.localstoreservice.isAuthrised(this.authChunk, item.auth) &&
              this.localstoreservice.isAuthrised(
                this.authChunk,
                item.parentAuth
              )
            ) {
              taxAccess = true;
              this.router.navigate(["/taxSetup/" + item.route]);
              break;
            }
          }
          if (!taxAccess) {
            this.messageService.add({
              closable: true,
              sticky: true,
              severity: "error",
              summary: this.translate.instant("error"),
              detail: this.translate.instant("access_denied"),
            });
          }
          return false;
        }

        if (
          route.data.auth &&
          this.localstoreservice.isAuthrised(this.authChunk, route.data.auth)
        ) {
          urlAccessAuth = true;
        }
        if (
          route.data.catAuth &&
          this.localstoreservice.isAuthrised(this.authChunk, route.data.catAuth)
        ) {
          urlCatAuth = true;
        }
        if (
          route.data.addOnAuth &&
          this.localstoreservice.isAuthrised(
            this.authChunk,
            route.data.addOnAuth
          )
        ) {
          urlAddOnAuth = true;
        }
        if (
          route.data.auth &&
          !urlAccessAuth &&
          (!route.data.addOnAuth || (route.data.addOnAuth && !urlAddOnAuth))
        ) {
          this.messageService.add({
            closable: true,
            sticky: true,
            severity: "error",
            summary: this.translate.instant("error"),
            detail: this.translate.instant("access_denied"),
          });
          return false;
        }
        if (!urlCatAuth) {
          this.messageService.add({
            closable: true,
            sticky: true,
            severity: "error",
            summary: this.translate.instant("error"),
            detail: this.translate.instant("access_denied"),
          });
          return false;
        }
        if (
          urlCatAuth &&
          !urlAccessAuth &&
          urlAddOnAuth &&
          route.params &&
          route.params.flag == "create"
        ) {
          this.messageService.add({
            closable: true,
            sticky: true,
            severity: "error",
            summary: this.translate.instant("error"),
            detail: this.translate.instant("access_denied"),
          });
          return false;
        }
        if (
          !(
            route.routeConfig.path == "setup" ||
            route.routeConfig.path == "dashboard"
          ) &&
          this.isprogrees &&
          this.localstoreservice.isAuthrised(this.authChunk, "USER_CREATE") &&
          this.localstoreservice.isAuthrised(
            this.authChunk,
            "SEAMLESS_SIDEMENU_SETUP"
          )
        ) {
          this.router.navigate([
            this.localstoreservice.isAuthrised(
              this.authChunk,
              "SEAMLESS_DASHBOARD"
            )
              ? "/dashboard"
              : "/setup",
          ]);
          return false;
        }
        let sideBarAuth = route.data.authsibebar
          ? route.data.authsibebar.split(",")
          : "";
        if (sideBarAuth && sideBarAuth.length) {
          urlAccessAuthSidebar = true;
          sideBarAuth.forEach((auth) => {
            if (!this.localstoreservice.isAuthrised(this.authChunk, auth)) {
              urlAccessAuthSidebar = false;
            }
          });
        }

        //handle redirection if authentication fails
        if ((!urlAccessAuth && !urlAddOnAuth) || !urlAccessAuthSidebar) {
          const userRole = this.currentUser["ROLE"];

          if (userRole == "Manager") {
            // this.router.navigate(['/manager']);
            this.router.navigate([
              this.localstoreservice.isAuthrised(
                this.authChunk,
                "SEAMLESS_DASHBOARD"
              )
                ? "/dashboard"
                : "/setup",
            ]);

            return false;
          }

          if (userRole == "SystemManager") {
            if (
              this.localstoreservice.isAuthrised(
                this.authChunk,
                "SEAMLESS_SIDEMENU_ENTITIES"
              )
            ) {
              this.router.navigate(["/Entities"]);
            } else {
              this.router.navigate(["/systemmanager"]);
            }
            return false;
          }

          if (userRole == "User" || userRole == "Reporting") {
            let sideLinks = [];
            this.otpPassword.userComponent.forEach((element) => {
              if (
                element["auth"] &&
                this.currentUser &&
                this.currentUser.authList
              ) {
                if (
                  this.currentUser &&
                  this.localstoreservice.isAuthrised(
                    this.authChunk,
                    element["auth"]
                  )
                ) {
                  sideLinks.push(element);
                }
              } else {
                sideLinks.push(element);
              }
            });
            if (sideLinks.length == 0 || sideLinks[0] == undefined) {
              // this.router.navigate(["/reportingPortal"]);
              this.messageService.add({ sticky: true, severity: 'error', summary: "Error", detail: "You don't have sufficient permissions , please check with your system admin first" });
              this.router.navigate(["/login"]);
              return false;
            } else {
              this.router.navigate([sideLinks[0].routeOrFunction]);
              return false;
            }
          }
        }
      }

      //check if route config has same role as login user
      const userRole = this.currentUser["ROLE"];
      if (
        route.data.role &&
        route.data.role.length &&
        !route.data.role.includes(userRole)
      ) {
        if (userRole == "Manager") {
          this.router.navigate([
            this.localstoreservice.isAuthrised(
              this.authChunk,
              "SEAMLESS_DASHBOARD"
            )
              ? "/dashboard"
              : "/setup",
          ]);
          // this.router.navigate(['/manager']);
          return false;
        } else if (userRole == "SystemManager") {
          if (
            this.localstoreservice.isAuthrised(
              this.authChunk,
              "SEAMLESS_SIDEMENU_ENTITIES"
            )
          ) {
            this.router.navigate(["/Entities"]);
          } else {
            this.router.navigate(["/systemmanager"]);
          }
          return false;
        } else if (userRole == "Reporting") {
          this.router.navigate(["/reportingPortal"]);
          return false;
        } else {
          let sideLinks = [];
          this.otpPassword.userComponent.forEach((element) => {
            if (
              element["auth"] &&
              this.currentUser &&
              this.currentUser.authList
            ) {
              if (
                this.currentUser &&
                this.localstoreservice.isAuthrised(
                  this.authChunk,
                  element["auth"]
                )
              ) {
                sideLinks.push(element);
              }
            } else {
              sideLinks.push(element);
            }
          });
          if (sideLinks.length == 0 || sideLinks[0] == undefined) {
            this.messageService.add({ sticky: true, severity: 'error', summary: "Error", detail: "You don't have sufficient permissions , please check with your system admin first" });
            // this.router.navigate(["/reportingPortal"]);
            this.router.navigate(["/login"]);
            return false;
          } else {
            this.router.navigate([sideLinks[0].routeOrFunction]);
            return false;
          }
        }
      } else {
        //all authentication pass allow routing
        return true;
      }
    } else {
      // start a new navigation to redirect to login page
      this.router.navigate(["/login"]);
      // abort current navigation
      return false;
    }
  }

  getCurrentUser() {
    this.loading = true;
    let cookieObject = {};
    this.authService.getCurrentUser(
      cookieObject,
      (cbs) => {
        if (cbs && cbs.id) {
          this.localstoreservice.setItem("tfss", JSON.stringify(cbs));
          if (this.translate.currentLang) {
            this.translate.reloadLang(this.translate.currentLang).subscribe(
              (res) => {
                //set session with updated file
                this.translate.use(this.translate.currentLang);
                this.translate.setDefaultLang(this.translate.currentLang);
                this.optPasswordService.setOtpLessSession(cbs);
              },
              (error) => {
                //set session with default file
                this.translate.use("English");
                this.translate.setDefaultLang("English");
                this.optPasswordService.setOtpLessSession(cbs);
              }
            );
          } else {
            this.translate.use("English");
            this.translate.setDefaultLang("English");
            this.optPasswordService.setOtpLessSession(cbs);
          }
        }
      },
      (cbe) => {
        this.loading = false;
      }
    );
  }
}

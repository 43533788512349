<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->

<span class="material-icons-outlined agfile_download" *ngIf="getDownloadFunctionName"
    (click)="downloadFile($event)">file_download</span>
<span class="material-icons-outlined rundailyprocess" *ngIf="exicuteBtn"
    (click)="runDailyProcessingCompliance($event)">play_arrow</span>
	<span *ngIf="moduleName =='accountSummary'" matTooltip="{{'Play'|translate}}"
        class="material-icons-outlined agvisibility" (click)="refreshGLBalance('play')">play_circle_outline</span>
        <span *ngIf="moduleName =='accountSummary'" matTooltip="{{'refresh'|translate}}"
        class="material-icons-outlined agvisibility" (click)="refreshGLBalance('refresh')">refresh</span>
<!-- <span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName &&  tradeDeleteView &&(moduleName != ('inputConfiguration') && moduleName!=('tradeUpload') && moduleName != ('user') && moduleName != ('documentManagement')) && moduleName != 'sourceDestTradeTransferTaxLotInfo' && moduleName != 'sequentialProcessStatusReview' && moduleName != 'transferTradeRawDataHistory'&& moduleName != 'RapidTransferTradeRawDataHistory' && moduleName != 'transferTradeRowData' && moduleName != 'batch' && moduleName != 'scheduleTaskInfo' && moduleName != ('workflowStatus')"
    (click)="viewRowData($event)">visibility</span> -->
    <span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName &&  tradeDeleteView && updateClientCheck &&(moduleName != ('enterprise') && moduleName != ('inputConfiguration') && moduleName!=('tradeUpload') && moduleName != ('user') && moduleName != ('documentManagement')) && moduleName != 'sourceDestTradeTransferTaxLotInfo' && moduleName != 'sequentialProcessStatusReview' && moduleName != 'transferTradeRawDataHistory'&& moduleName != 'RapidTransferTradeRawDataHistory' && moduleName != 'transferTradeRowData' && moduleName != 'batch' && moduleName != 'scheduleTaskInfo' && moduleName !='financialDataIntegration' && moduleName != ('workflowStatus') && (componentName != 'accountSummaryReconcile' && componentName != 'rapidAccountSummaryReconcile' && componentName !='calculateTaxAllocation')"
    (click)="viewRowData($event)">visibility</span>
  
    <span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName &&  tradeDeleteView && moduleName == ('financialDataIntegration') && status=='Pending'"
    (click)="viewRowData($event)">visibility</span>
    <span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName &&  tradeDeleteView && moduleName == ('enterprise') && status!='init'"
    (click)="viewRowData($event)">visibility</span>
    <span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName &&  tradeDeleteView && moduleName == ('enterprise') && status=='init'"
    (click)="viewRowData($event)">edit</span>
<!-- Auth:VG Download Buttom Docs -->
<span class="material-icons-outlined agvisibility" *ngIf="moduleName == 'documentManagement'"
    (click)="downloadRowDoc($event)">cloud_download</span>
<span class="material-icons-outlined agvisibility" *ngIf="(moduleName == 'batch') && updateUserCheck" (click)="updateBatch($event)"
    matTooltip="{{'update'|translate}}">edit</span>
<span class="material-icons-outlined agvisibility agpayments" *ngIf="moduleName == 'paymentsPayable' && !isPaymentActive" (click)="updatePayment($event)"
    matTooltip="{{'make_payments'|translate}}">add_card</span>

<!-- calculateTaxAllocationStatus ExportBtn-->
<span class="material-icons-outlined agvisibility"
    *ngIf="componentName == 'calculateTaxAllocationStatus' && authList.indexOf('SEAMLESS_TAXATION_TAXALLOCATION_EXPORTFILTER')>-1 "
    (click)="calculateExportDoc()" matTooltip="{{'Export Data'|translate}}">file_download</span>
<!-- calculateTaxAllocationStatus ExportBtn-->

<span class="material-icons-outlined agvisibility" *ngIf="moduleName == 'batch'" (click)="batchScduleDetail2($event)"
    matTooltip="{{'schedule'|translate}}">schedule</span>
<span class="material-icons-outlined agvisibility" *ngIf="moduleName == 'scheduleTaskInfo'"
    (click)="batchScduleDetail($event)" matTooltip="{{'schedule'|translate}}">schedule</span>

<span title="Play" *ngIf="!isActive && moduleName == 'scheduleTaskInfo'" class="material-icons-outlined agvisibility"
    (click)="updateSchdule($event)">play_circle_filled</span>

<span title="Stop" *ngIf="isActive && moduleName == 'scheduleTaskInfo'" class="material-icons-outlined agvisibility"
    (click)="updateSchdule($event)">stop_circle</span>
    
    <span class="material-icons-outlined agvisibility"
        *ngIf="((componentName == 'accountSummaryReconcile' || componentName == 'rapidAccountSummaryReconcile') && isMemoEntryCheck == true )"
        (click)="setMemoEntry()">visibility</span>
  
 <span class="material-icons-outlined agvisibility" *ngIf="moduleName == 'user' || moduleName == 'enterprise'"
    (click)="viewRowData($event)">visibility</span>
<span class="material-icons-outlined agclear" matTooltip="{{'delete'|translate}}"
    *ngIf="getDeleteFunctionName && displayDeleteButton && tradeDeleteView && (csdEnterpriseId == null) &&  (moduleName != ('inputConfiguration') && moduleName != ('sourceDestTradeTransferTaxLotInfo') &&  moduleName != ('user') && moduleName != ('manageJobSchedule')
&& moduleName != ('reportingPortalUser')  && moduleName != ('reportNotificationStatus') && moduleName != ('workflowStatus') ) && componentName != 'rapidOmslist' && componentName !='calculateTaxAllocation' && componentName !='calculateTaxAllocationStatus' && ((componentName == 'accountSummaryReconcile' || componentName == 'rapidAccountSummaryReconcile')? isMemoEntryCheck == true:true )" (click)="deleteSingleRow($event)">clear</span>

<span class="material-icons-outlined agclear" matTooltip="{{'delete'|translate}}"
    *ngIf=" moduleName == 'manageJobSchedule' && getDeleteFunctionName" (click)="deleteData($event)">clear</span>

<span *ngIf="moduleName == 'manageJobSchedule' && playPauseButtonDis" matTooltip="{{'Play'|translate}}"
    class="material-icons-outlined agvisibility" (click)="playPauseSchedule('resume')">play_circle_outline</span>
<span *ngIf="moduleName == 'manageJobSchedule' && !playPauseButtonDis" matTooltip="{{'Pause'|translate}}"
    class="material-icons-outlined agvisibility" (click)="playPauseSchedule('pause')">pause_circle_outline</span>
<button *ngIf=" moduleName == 'workflowStatus' && getDeleteFunctionName" class="material-icons-outlined agclear"
    [disabled]="deleteButtonClick || rowDisabled"
    [ngStyle]="( deleteButtonClick || rowDisabled) && {'color': 'red',cursor: 'not-allowed','top':'6px'}"
    style="top:6px;color: red; cursor: pointer; background-color: transparent; text-align:center; position: relative; align-items: center; text-align: center; align-content: center;  border-style: none;"
    (click)="deleteUploadData($event)"><span matTooltip="{{'delete'|translate}}">clear</span>
</button>

<!--Toggle icon for Grid Auth:vg-->
<mat-slide-toggle class="material-icons-outlined" *ngIf="moduleName == 'inputConfiguration'"
    (change)="userActionfun($event)" [disabled]="userAction" [checked]="rowToggle" matTooltipPosition="above"
    matTooltip="{{rowToggle?'Enabled':'Disabled'}}"></mat-slide-toggle>



<span class="material-icons-outlined agomsvisibility" *ngIf="omsEdit" (click)="viewRowData($event)"
    matTooltipPosition="right" matTooltip="{{'edit'|translate}}">visibility</span>
<div class="material-icons-outlined aggrid_view" *ngIf="omsApproveList" (click)="findByIdAllocated($event)"
    matTooltipPosition="right" matTooltip="{{'allocated_trade_list'|translate}}"> grid_view
</div>
<div class="material-icons-outlined agedit_note" *ngIf="omsEditList" (click)="updateApproveOrder($event)"
    matTooltip="{{'update'|translate}}"> edit_note
</div>
<div class="material-icons-outlined agomscancel" *ngIf="getDeleteFunctionName && omsCancel && (componentName != 'rapidOmslist' ||  (getDeleteFunctionName && componentName == 'rapidOmslist'))" (click)="deleteSingleRow($event)"
    matTooltipPosition="right" matTooltip="{{'cancel'|translate}}">clear </div>
<div class="pi pi-times-circle agdeleteall" *ngIf="omsDeleteAllo" (click)="cancelApproveOrder($event)"
    matTooltip="{{'cancel'|translate}}"> </div>



    <span class="material-icons-outlined agclear" matTooltip="{{'delete'|translate}}"
    *ngIf="getDeleteFunctionName && (componentName == 'usermanagementlist' && SioSubcomponent != 'SIO') "
    (click)="deleteSingleRow($event)">clear</span> 

  
<!-- <span class="material-icons-outlined agclear" matTooltip="{{'delete'|translate}}"
    *ngIf="moduleName == 'enterprise'"
    (click)="deleteSingleRow($event)">clear</span> -->

    <button class="material-icons-outlined agclear unlockable-button" *ngIf="componentName == 'FSAutomation'"
        (click)="fSConfigArchive('delete')" [disabled]="fsConfigLocked" [ngClass]="{'locked': fsConfigLocked}"
        matTooltip="{{'delete'|translate}}">
        <span>delete</span>
    </button>
    <button class="material-icons-outlined agclear unlockable-button" *ngIf="componentName == 'archivedFSAutomation'"
        (click)="fSConfigArchive('Unarchive')" [disabled]="fsConfigLocked" [ngClass]="{'locked': fsConfigLocked}"
        matTooltip="{{'unarchive'|translate}}">
        <span>unarchive</span>
    </button>
    <button class="material-icons-outlined agvisibility unlockable-button" *ngIf="componentName == 'FSAutomation'"
        (click)="fSConfiglock('lock')" [disabled]="fsConfigLocked" [ngClass]="{'locked': fsConfigLocked}"
        [matTooltip]="fsConfigLocked ? 'Locked' : 'Unlocked'">
        <span>{{ fsConfigLocked ? 'lock' : 'lock_open' }}</span>
    </button>
    <button class="material-icons-outlined agvisibility unlockable-button" *ngIf="componentName == 'FSAutomationUnlock'"
        (click)="fSConfiglock('unlock')"
        [matTooltip]='Locked'>
        <span>lock</span>
    </button>

    <!-- comment code hide Delete Icon User Component List -->
<span class="material-icons-outlined agclear" matTooltip="{{'delete'|translate}}"
    *ngIf="getDeleteFunctionName && (componentName== 'allUsers')"
    (click)="deleteSingleRow($event)">clear</span>
/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';

@Injectable({
  providedIn: 'root'
})
export class SetupButtonService {
  amcList: any;
  fundList: any;
  custodian: any;
  investor: any;
  assestBasedFee: any;
  performanceFee: any;
  setupActionsubject = new Subject<any>();
  tabhighlightsubject = new Subject<any>();
  componentName: string = 'setupcustodianList';


  constructor(private http: HttpClient, private setupPercentageService: SetupPercentageService) {

    this.setupPercentageService.getCustodianType().subscribe((res) => {

      console.log("res111>>>>>", res);
      if (res == 'seamlessLegacy') {
        // this.router.navigate(["/manager/custodians"]);
        this.componentName = 'custodianListLegacy';
      } else {
        this.componentName = 'setupcustodianList';
      }

    })
  }

  setupManagementTabs = [
    { id: 1, aliasName: "amc", name: 'amc_im_gp', isSubmit: false, isDisabled: false, description: 'amc_description', viewRouterlink: "amc-im-program", createRouterlink: 'amc-im-program-entry/create', uploadRouterlink: 'amc-im-program-upload', authView: '', authCreate: 'ASSETMANAGEMENT_CREATE', authUpload: '',videoedit:true ,displayOrder:1},
    { id: 2, aliasName: "fund", name: 'funds_client_accounts', isSubmit: false, isDisabled: true, description: 'fund_client_account_description', authView: '', createRouterlink: 'fund-account/create', viewRouterlink: 'fund-client-account-list', uploadRouterlink: 'fund-client-account-upload', authUpload: '', authCreate: '',videoedit:true ,displayOrder:1},
    { id: 3, aliasName: "custodian", name: 'account_configurations', isSubmit: false, isDisabled: true, description: 'account_configurations_description', authView: '', createRouterlink: this.componentName == 'custodianListLegacy' ? 'custodianst-legacy-entry/create' : 'custodianst-entry/create', viewRouterlink: this.componentName == 'custodianListLegacy' ? 'custodians-legacy-list' : 'custodians-list', uploadRouterlink: this.componentName == 'custodianListLegacy' ? 'custodians-legacy-upload' : 'custodians-upload', authUpload: '', authCreate: '',videoedit:true,displayOrder:1},
    { id: 4, aliasName: "investor", name: 'investors', isSubmit: false, isDisabled: true, description: 'investors_description', authView: '', createRouterlink: 'investor-entry/create', viewRouterlink: 'investor-list', uploadRouterlink: 'investor-upload', authUpload: '', authCreate: '',videoedit:true ,displayOrder:1},
    { id: 5, aliasName: "investManager", name: 'assest_based_fee', isSubmit: false, isDisabled: true, description: 'assest_based_fee_description', authView: '', viewRouterlink: 'asset-based-fee', createRouterlink: 'asset-based-fee-entry/create', uploadRouterlink: '', authCreate: '', authUplaod: '',videoedit:true,displayOrder:1},
    { id: 6, aliasName: "managerPerfBasedFee", name: 'performance_based_fee', isSubmit: false, isDisabled: true, description: 'performance_based_fee_description', authView: '', viewRouterlink: 'performanc-based-fee', createRouterlink: 'performanc-based-fee-entry/create', uploadRouterlink: '', authCreate: '', authUplaod: '',videoedit:true ,displayOrder:1},
  ];

  getBasicDetails() {
    return new Promise(resolve => {
      this.fetchMultipleApiSector().subscribe(res => {
        this.amcList = res[0].resultList && res[0].resultList.length ? res[0].resultList.map(element => ({ label: element.fundName, value: { "id": element.id }, alias: element.fundAlias })) : [];
        this.fundList = res[1].resultList && res[1].resultList.length ? res[1].resultList.map(element => ({ label: element.nameOfFee, value: element.nameOfFee })) : [];
        this.custodian = res[2].resultList && res[2].resultList.length ? res[2].resultList.map(element => ({ label: element.nameOfFee, value: element.nameOfFee })) : [];
        this.investor = res[3].resultList && res[3].resultList.length ? res[3].resultList.map(element => ({ label: element.nameOfFee, value: element.nameOfFee })) : [];
        this.assestBasedFee = res[4].resultList && res[4].resultList.length ? res[4].resultList.map(element => ({ label: element.nameOfFee, value: element.nameOfFee })) : [];
        this.performanceFee = res[5].resultList && res[5].resultList.length ? res[5].resultList.map(element => ({ label: element.nameOfFee, value: element.nameOfFee })) : [];
        //  let basicTemp = this.basicDetails();
        // resolve(basicTemp)
      })
    })
  }

  getPrecentageProgress(username: any): Observable<any> {
    return this.http.get('userPerferenceV2/searchV2?view=ProgressPercentage&userName=' + username);
  }
  checkSetupProgress(): Observable<any> {
    return this.http.get('enterpriseV2/checkSetupProgress');
  }

  fetchMultipleApiSector(): Observable<any> {
    let response1 = this.http.get<any>('assetManagementV2/records/false/0');
    let response2 = this.http.get<any>('fundV2/records/false/0');
    let response3 = this.http.get<any>('brokerAccountV2/records/false/0');
    let response4 = this.http.get<any>('brokerAccountV2/records/false/0');
    let response5 = this.http.get<any>('brokerAccountV2/records/false/0');
    let response6 = this.http.get<any>('brokerAccountV2/records/false/0');
    return forkJoin([response1, response2, response3, response4, response5, response6]);
  }

  hideAllSection() {
    document.querySelectorAll(".mat-vertical-content-container").forEach((item, index) => {
      if (index != 0) {
        item['style'].cssText = `
      display: none;
      animation:  500ms ease-in-out both;
    `;
      }
    });
  }
  showAllSection() {
    document.querySelectorAll(".mat-vertical-content-container").forEach(item => {
      item['style'].cssText = `
      display: block;
      animation:  500ms ease-in-out both;
    `;
    });
  }

  chagngeTabIcon(index, evt) {
    document.querySelectorAll(".tab-hide").forEach((item, index1) => {
      if (index != index1) {
        if (item.children.length && item.children[0].classList.contains('adv-config')) {
          item.children[0].classList.remove('adv-config')
          item.children[0].classList.add('adv-config-after')
        }
      } else if (evt.target.children.length == 0) {
        if (item.children[0].classList.contains('adv-config-after')) {
          item.children[0].classList.remove('adv-config-after')
          item.children[0].classList.add('adv-config')
        } else {
          item.children[0].classList.remove('adv-config')
          item.children[0].classList.add('adv-config-after')
        }
      }
    })
  }

  advConfToggle(evt) {
    if (evt.target.classList.length) {
      if (evt.target.classList.contains('adv-config-acc-after')) {
        evt.target.classList.add('adv-config-acc')
        evt.target.classList.remove('adv-config-acc-after')
      } else {
        evt.target.classList.add('adv-config-acc-after')
        evt.target.classList.remove('adv-config-acc')
      }
    }

  }

  setupIcon() {
    document.querySelectorAll(".tab-hide").forEach((item, index1) => {
      if (index1 == 0) {
        if (item.children[0].classList.contains('adv-config')) {
          item.children[0].classList.remove('adv-config')
          item.children[0].classList.add('adv-config-after')
        }
      }
    })
  }

  getActionMethod() {
    return this.setupActionsubject.asObservable();
  }
  setActionMethod(data) {
    if (data)
      this.setupActionsubject.next(data)
  }

  getTabSelected() {
    return this.tabhighlightsubject.asObservable();

  }
  setTabSelected(data) {
    if (data)
      this.tabhighlightsubject.next(data)
  }
  getSetupSetting(username: any): Observable<any> {
    return this.http.get('userPerferenceV2/searchV2?view=RapidSetupSetting&userName=' + username);
  }
  setSetupSetting(data: any) {
    return this.http.post('userPerferenceV2/create', data)
  }
  updateSetupSetting(data) {
    return this.http.put<any>('userPerferenceV2/update', data);

  }

}

<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<div class="search-wrapper" fxLayout="row" [ngClass]="{ 'focus': focused, 'has-input': input }" fxFlex
  (furyClickOutside)="closeDropdown()">
  <mat-icon class="search-icon">search</mat-icon>
  <input #isFocused type="text" (input)='Search()' autocomplete="new-password" spellcheck="false" class="search-input"
    [(ngModel)]="input" (focus)="openDropdown()" placeholder="{{'search' | translate}}...">

  <div *ngIf="roleAs=='User'" class="search-dropdown">
    <div class="content results" *ngIf="input" fxLayout="column">
      <div class="heading" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="icon">search</mat-icon>
        {{'search_result' | translate}}: {{ input }}
      </div>
      <div class="items" *ngFor="let element of userTabsX">
        <div class="item" *ngIf="currentUser.authList.indexOf(element.auth)>-1">
          <div class="itemname"
            *ngIf="!element?.isQueryString && (!element.catViewAuth || currentUser.authList.includes(element.catViewAuth))"
            matRipple [routerLink]="[element.isUploadAuth ? element.uploadRouterlink : element.viewRouterlink]"> {{element.name | translate}}</div>
          <div class="itemname"
            *ngIf="element?.isQueryString && (!element.catViewAuth || currentUser.authList.includes(element.catViewAuth))"
            matRipple [routerLink]="[element.viewRouterlink]" [queryParams]="element.queryParam"> {{element.name |
            translate}}</div>
          <div class="itemname"
            *ngIf="!element?.isQueryString && (element.catViewAuth && !currentUser.authList.includes(element.catViewAuth))"
            matRipple> {{element.name | translate}}</div>
          <div class="itemname"
            *ngIf="element?.isQueryString && (element.catViewAuth && !currentUser.authList.includes(element.catViewAuth))"
            matRipple [queryParams]="element.queryParam"> {{element.name | translate}}</div>

          <div class="iconset">
            <span
              *ngIf="element.createRouterlink !='' && currentUser.authList.indexOf(element.authCreate)>- 1 && (!element.catCreatAuth || currentUser.authList.includes(element.catCreatAuth))"
              class="material-icons-outlined" [routerLink]='[element.createRouterlink, "create"]'>add</span>
            <span
              *ngIf="element.uploadRouterlink !='' && currentUser.authList.indexOf(element.authUpload)>- 1 && (!element.catCreatAuth || currentUser.authList.includes(element.catCreatAuth))"
              class="material-icons-outlined " [routerLink]='[element.uploadRouterlink]'>file_upload</span>
            <span
              *ngIf="element.viewRouterlink !='' && element.viewRouterlink !='vendorcreate' && element.viewRouterlink !='/setting/api-key-usage/create'  &&!element.isQueryString &&  (!element.catViewAuth || currentUser.authList.includes(element.catViewAuth))"
              class="material-icons-outlined" class=" material-icons-outlined" [routerLink]='[element.viewRouterlink]'>
              visibility</span>
            <span
              *ngIf="element.viewRouterlink !='' && element.viewRouterlink !='vendorcreate' && element.viewRouterlink !='/setting/api-key-usage/create' && element.isQueryString &&  (!element.catViewAuth || currentUser.authList.includes(element.catViewAuth))"
              class="material-icons-outlined" class=" material-icons-outlined" [routerLink]='[element.viewRouterlink]'
              [queryParams]="element.queryParam"> visibility</span>
          </div>
        </div>
      </div>
    </div>

    <div class="content recents" fxLayout="row" *ngIf="!input">

      <div class="recently" fxLayout="column" fxFlex>
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">star</mat-icon>
          {{'favourites' | translate}}
        </div>
        <div class="items">
          <div class="item"
            *ngIf="currentUser.authList.indexOf('SEAMLESS_SIDEMENU_INPUT')>-1 && currentUser.authList.indexOf('SEAMLESS_INPUT_VIEW_TRADES')>-1 && (currentUser.authList.indexOf('SEAMLESS_INPUT_VIEW_TRADES_TRADE')>-1)"
            matRipple [routerLink]="['/input/view/trade-list']">{{'trade' | translate}}</div>
          <div class="item"
            *ngIf="currentUser.authList.indexOf('SEAMLESS_SIDEMENU_INPUT')>-1 && currentUser.authList.indexOf('SEAMLESS_INPUT_VIEW_SECURITYMASTER')>-1  && (currentUser.authList.indexOf('SEAMLESS_INPUT_VIEW_SECURITYMASTER_EQUITIES')>-1)"
            matRipple [routerLink]="['/input/view/sm-equities']">{{'sm_equities' | translate}} </div>
          <div class="item"
            *ngIf="currentUser.authList.indexOf('SEAMLESS_SIDEMENU_INPUT')>-1 && currentUser.authList.indexOf('SEAMLESS_INPUT_VIEW_PORTFOLIO')>-1  && (currentUser.authList.indexOf('SEAMLESS_INPUT_VIEW_PORTFOLIO_MARKETDATA')>-1)"
            matRipple [queryParams]="{openModel:true}" [routerLink]="['/input/view/market-data']">{{'fetch_market_data'
            |
            translate}}</div>
          <div class="item"
            *ngIf="currentUser.authList.indexOf('SEAMLESS_SIDEMENU_INPUT')>-1 && currentUser.authList.indexOf('SEAMLESS_INPUT_VIEW_ACCOUNTING')>-1  && (currentUser.authList.indexOf('SEAMLESS_INPUT_VIEW_ACCOUNTING_NONTRADEACTIVITY')>-1)"
            matRipple [routerLink]="['/input/view/nontradeactivity']">{{'non_trade_activity' |
            translate}}</div>

        </div>
      </div>

      <div class="frequently" fxLayout="column" fxFlex fxFlexOffset="16px">
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">youtube_searched_for</mat-icon>
          {{'frequently_visited' | translate}}
        </div>
        <div class="items">
          <div class="item" *ngIf="currentUser.authList.indexOf('SIDEMENU_TRADES')>-1" matRipple
            [routerLink]="['/trades']">{{'trades_module' | translate}}</div>
          <div class="item" *ngIf="currentUser.authList.indexOf('SIDEMENU_PORTFOLIO_RISK')>-1" matRipple
            [routerLink]="['/portfolio']">{{'portfolio' | translate}}</div>
          <div class="item" *ngIf="currentUser.authList.indexOf('SIDEMENU_FUNDACCOUNTING')>-1" matRipple
            [routerLink]="['/accounting']">{{'accounting' | translate}}</div>
          <div class="item" *ngIf="currentUser.authList.indexOf('SIDEMENU_INVESTOR')>-1" matRipple
            [routerLink]="['/investor']">{{'investor' | translate}}</div>
          <div class="item" *ngIf="currentUser.authList.indexOf('SIDEMENU_DAILY_PROCESSING')>-1" matRipple
            [routerLink]="['/processing']">{{'processing' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- ent user global search -->
  <div *ngIf="roleAs=='Manager'" class="search-dropdown">
    <div class="content results" *ngIf="input" fxLayout="column">
      <div class="heading" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="icon">search</mat-icon>
        {{'search_result' | translate}}: {{ input }}
      </div>
      <div class="items" *ngFor="let element of entTabsX">
        <div class="item">
          <div class="itemname"
            *ngIf="!element?.isQueryString && (!element.catViewAuth || currentUser.authList.includes(element.catViewAuth))"
            matRipple [routerLink]="[element.isUploadAuth ? element.uploadRouterlink : element.viewRouterlink]" matTooltip="{{element?.tooltip | translate}}">
            {{element.name| translate}}</div>
          <div class="itemname"
            *ngIf="element?.isQueryString &&(!element.catViewAuth  || currentUser.authList.includes(element.catViewAuth))"
            matRipple [routerLink]="[element.viewRouterlink]" [queryParams]="element.queryParam"
            matTooltip="{{element?.tooltip | translate}}">{{element.name| translate}}</div>
          <div class="itemname"
            *ngIf="!element?.isQueryString &&(element.catViewAuth && !currentUser.authList.includes(element.catViewAuth))"
            matRipple matTooltip="{{element?.tooltip | translate}}">{{element.name| translate}}</div>
          <div class="itemname"
            *ngIf="element?.isQueryString &&(element.catViewAuth && !currentUser.authList.includes(element.catViewAuth))"
            matRipple [queryParams]="element.queryParam" matTooltip="{{element?.tooltip | translate}}">{{element.name|
            translate}}</div>

          <div class="iconset">
            <span
              *ngIf="(element.createRouterlink !='' && element.createRouterlink !='/manager/exchange-wallet'&&!element.moduleName) &&  (!element.catCreatAuth || currentUser.authList.includes(element.catCreatAuth)) "
              class="material-icons-outlined" [routerLink]='[element.createRouterlink, "create"]'>add</span>
            <span
              *ngIf="(element.subComponent && element.createRouterlink !=''&& element.createRouterlink !='/manager/exchange-wallet'&&element.moduleName) &&  (!element.catCreatAuth || currentUser.authList.includes(element.catCreatAuth))"
              class="material-icons-outlined" [routerLink]='[element.createRouterlink]'
              [queryParams]="{action:'create',subComponent:element.subComponent}">add</span>
            <span
              *ngIf="(!element.subComponent && element.createRouterlink !=''&& element.createRouterlink !='/manager/exchange-wallet' && element.moduleName) &&  (!element.catCreatAuth || currentUser.authList.includes(element.catCreatAuth))"
              class="material-icons-outlined" [routerLink]='[element.createRouterlink]'>add</span>
            <span
              *ngIf="element.createRouterlink !='' && element.createRouterlink =='/manager/exchange-wallet'&&!element.moduleName"
              class="material-icons-outlined" [routerLink]='[element.createRouterlink]'>add</span>
            <span
              *ngIf="(element.uploadRouterlink !='' && element.uploadRouterlink !='/manager/fetch-live-data'&&element.uploadRouterlink !='customization/fetch-live-data') &&  (!element.catCreatAuth || currentUser.authList.includes(element.catCreatAuth))"
              class="material-icons-outlined " [routerLink]='[element.uploadRouterlink]'>file_upload</span>
            <span
              *ngIf="element.uploadRouterlink !='' && (element.uploadRouterlink =='/manager/fetch-live-data'||element.uploadRouterlink =='customization/fetch-live-data')"
              class="material-icons-outlined " [routerLink]='[element.uploadRouterlink]'>file_download</span>
            <span
              *ngIf="(!element.isQueryString && element.viewRouterlink !='' && element.viewRouterlink !='/manager/exchange-wallet' && element.viewRouterlink !='/setting/api-key-usage/create' && element.viewRouterlink !='/manager/fetch-live-data' && element.viewRouterlink !='vendorcreate'&&element.viewRouterlink!='customization/notes-comments-entry'&&element.viewRouterlink!='customization/fetch-live-data') &&  (!element.catCreatAuth || currentUser.authList.includes(element.catViewAuth))"
              class="material-icons-outlined" class=" material-icons-outlined" [routerLink]='[element.viewRouterlink]'>
              visibility</span>
            <span
              *ngIf="(element.isQueryString && element.viewRouterlink !='' && element.viewRouterlink !='/manager/exchange-wallet' && element.viewRouterlink !='/setting/api-key-usage/create' && element.viewRouterlink !='/manager/fetch-live-data' && element.viewRouterlink !='vendorcreate'&&element.viewRouterlink!='customization/notes-comments-entry'&&element.viewRouterlink!='customization/fetch-live-data')&&  (!element.catCreatAuth || currentUser.authList.includes(element.catViewAuth))"
              class="material-icons-outlined" class=" material-icons-outlined" [queryParams]="element.queryParam"
              [routerLink]='[element.viewRouterlink]'>
              visibility</span>

          </div>
        </div>
      </div>
    </div>

    <div class="content recents" fxLayout="row" *ngIf="!input">
      <div class="recently" fxLayout="column" fxFlex>
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">star</mat-icon>
          {{'favourites' | translate}}
        </div>
        <div class="items">
          <div class="item" matRipple [routerLink]="['user-management/user-list/Seamless']">{{'users' | translate}}
          </div>
          <div class="item" matRipple [routerLink]="['setup/investor-list']">{{'investors' | translate}} </div>

        </div>
      </div>

      <div class="frequently" fxLayout="column" fxFlex fxFlexOffset="16px">
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">youtube_searched_for</mat-icon>
          {{'frequently_visited' | translate}}
        </div>
        <div class="items">
          <div class="item" matRipple [routerLink]="['/setup']">{{'setup' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- system Manager user global search -->
  <div *ngIf="roleAs=='SystemManager'&&currentUser.authList.includes('SEAMLESS_SIDEMENU_SYSADMIN')"
    class="search-dropdown">
    <div class="content results" *ngIf="input" fxLayout="column">
      <div class="heading" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="icon">search</mat-icon>
        {{'search_result' | translate}}: {{ input }}
      </div>
      <div class="items" *ngFor="let element of systemAdminTabsX">
        <div class="item">
          <div class="itemname" matRipple [routerLink]="[element.viewRouterlink]">{{element.name| translate}}</div>

          <div class="iconset">
            <span *ngIf="element.createRouterlink !=''" class="material-icons-outlined"
              [routerLink]='[element.createRouterlink, "create"]'>add</span>
            <span *ngIf="element.uploadRouterlink !=''" class="material-icons-outlined "
              [routerLink]='[element.uploadRouterlink]'>file_upload</span>
            <span *ngIf="element.viewRouterlink !=''" class="material-icons-outlined" class=" material-icons-outlined"
              [routerLink]='[element.viewRouterlink]'> visibility</span>
          </div>
        </div>
      </div>
    </div>

    <div class="content recents" fxLayout="row" *ngIf="!input">
      <div class="recently" fxLayout="column" fxFlex>
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">star</mat-icon>
          {{'favourites' | translate}}
        </div>
        <div class="items">
          <div class="item" matRipple [routerLink]="['/systemmanager/enterprisesList']">{{'enterprises' | translate}}
          </div>
          <div class="item" matRipple [routerLink]="['/systemmanager/workflowStatus']">{{'data_import_status' |
            translate}} </div>
          <div class="item" matRipple [routerLink]="['/systemmanager/connectuserandfund']">{{'connect_user_fund' |
            translate}}</div>
          <div class="item" matRipple [routerLink]="['/systemmanager/taxClassification']">{{'tax_classification' |
            translate}}</div>

        </div>
      </div>

      <div class="frequently" fxLayout="column" fxFlex fxFlexOffset="16px">
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">youtube_searched_for</mat-icon>
          {{'frequently_visited' | translate}}
        </div>
        <div class="items">
          <div class="item" matRipple [routerLink]="['/systemmanager']">{{'system_admin' | translate}}</div>

        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="roleAs=='SystemManager'&&currentUser.authList.includes('SEAMLESS_SIDEMENU_MIS')&&!currentUser.authList.includes('SEAMLESS_SIDEMENU_SYSADMIN')"
    class="search-dropdown">
    <div class="content results" *ngIf="input" fxLayout="column">
      <div class="heading" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="icon">search</mat-icon>
        {{'search_result' | translate}}: {{ input }}
      </div>
      <div class="items" *ngFor="let element of misTabsX">
        <div class="item">
          <div class="itemname" matRipple [routerLink]="[element.viewRouterlink]">{{element.name| translate}}</div>

          <div class="iconset">
            <span *ngIf="element.createRouterlink !=''" class="material-icons-outlined"
              [routerLink]='[element.createRouterlink, "create"]'>add</span>
            <span *ngIf="element.uploadRouterlink !=''" class="material-icons-outlined "
              [routerLink]='[element.uploadRouterlink]'>file_upload</span>
            <span *ngIf="element.viewRouterlink !=''" class="material-icons-outlined" class=" material-icons-outlined"
              [routerLink]='[element.viewRouterlink]'> visibility</span>
          </div>
        </div>
      </div>
    </div>

    <div class="content recents" fxLayout="row" *ngIf="!input">
      <div class="recently" fxLayout="column" fxFlex>
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">star</mat-icon>
          {{'favourites' | translate}}
        </div>
        <div class="items">
          <div class="item" matRipple [routerLink]="['/MIS/fund']">{{'funds_client_accounts' | translate}}
          </div>


        </div>
      </div>

      <div class="frequently" fxLayout="column" fxFlex fxFlexOffset="16px">
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">youtube_searched_for</mat-icon>
          {{'frequently_visited' | translate}}
        </div>
        <div class="items">
          <div class="item" matRipple [routerLink]="['/MIS']">{{'mis' | translate}}</div>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/   -->
<ng-template #ButtonTemplate>
  <mat-button-toggle-group class="splitgroupbtn" appearance="legacy">
    <mat-button-toggle matTooltip="{{'required_fields' | translate}}" [matTooltipDisabled]="!formValid"
      [disabled]="formValid">
      <button *ngIf="componentName != 'viewDataCollection'" mat-menu-item (click)="Search($event)"
        [disabled]="formValid">
        <span class="ui-text txt-upr-case"> {{'search'|translate}}</span>
      </button>
      <button *ngIf="componentName == 'viewDataCollection' && !isRapid" color="primary" mat-raised-button
        style="margin-right:5px;" [disabled]="formValid || isDoubleClick" class="btn-batch-apply"
        (click)="viewDataCollectionSubmit()">
        <span class="ui-text">{{'submit'|translate}}</span>
      </button>
      <button *ngIf="componentName == 'viewDataCollection' && isRapid" [disabled]="formValid  || isDoubleClick"
        mat-menu-item float="right" (click)="viewDataCollectionSubmit()" class="sv-btn">
        <span class="ui-text">
          {{'submit'|translate}}
        </span>
      </button>
    </mat-button-toggle>

    <mat-button-toggle *ngIf="!isRapid" class="splitbtn" color="primary" [matMenuTriggerFor]="appMenu"
      matTooltip="{{'click_view_more'|translate}}">
      <mat-icon>arrow_drop_down</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-menu #appMenu="matMenu">

    <button *ngIf="isNewBtn && getNewFunctionName" mat-mini-fab color="primary" matTooltip=" {{'create'|translate}}"
      matTooltipPosition="above" [routerLink]="routeUrl?.create">
      <mat-icon>add</mat-icon>
    </button>

    <button *ngIf="isUploadBtn && getuploadFunctionName" mat-mini-fab color="primary"
      matTooltip=" {{'upload'|translate}}" matTooltipPosition="above" [routerLink]="routeUrl['upload']">
      <mat-icon>file_upload</mat-icon>
    </button>
    <button mat-mini-fab color="primary" matTooltip=" {{'clear'|translate}}" matTooltipPosition="above" float="right"
      (click)="onClear()" [hidden]="hideOnEdit">
      <mat-icon> clear </mat-icon>
    </button>
  </mat-menu>
</ng-template>

<fury-page-layout mode="card" [ngClass]="{'input-card': (isinputui()  && isRapid)}">
  <fury-page-layout-header *ngIf="!isNotShowBreadCrum">
    <fury-breadcrumbs [crumbs]="[moduleNameGrid]" [cardName]="[card]" current="{{heading|translate}}">
    </fury-breadcrumbs>
  </fury-page-layout-header>
  <fury-page-layout-content [ngClass]="{'gridDisplay': (!displayForm), 'trade-view-input': (isinputui() && isRapid)}">
    <div fxLayout="column" fxLayoutAlign="start center" class="data-setui">
      <div class="card" [ngClass]="{'trade-view-card': (isinputui()  && isRapid)}">
        <mat-vertical-stepper #verticalStepper="matVerticalStepper"
          [ngClass]="{'trade-view-header': (isinputui() && isRapid)}">
          <mat-step *ngIf="formBasic">
            <ng-template matStepLabel>
              <label>{{heading|translate}}</label>
              <span [ngClass]="{'stickyButton': (!isRapid)}">
                <ng-template *ngTemplateOutlet="ButtonTemplate"></ng-template>
              </span>
            </ng-template>
            <div class="content" [ngClass]="{'rapidinput-tradelist-input': (isinputui() && isRapid)}" fxLayout="column"
              fxLayoutGap="8px">
              <app-dynamic-form [data]="basicDetails" (status)="formValidation($event)"
                (dataItemDetails)="setOnChange($event)"></app-dynamic-form>
            </div>
          </mat-step>
        </mat-vertical-stepper>
      </div>
    </div>
  </fury-page-layout-content>
  <fury-page-layout-content [ngClass]="{'gridDisplay': (!gridShow)}" [@fadeInUp]>


    <fury-list name="{{heading|translate}}">
      <div class="actions oms-list-btns uploadbtn_alignment gridbtn-ui">

        <div *ngIf="totalRecords && !istotalFilterCount" class="countClass"> {{'count'|translate}}:
          <span>{{totalRecords}}</span>
        </div>
        <div *ngIf="totalFltRecords && istotalFilterCount" class="countClass"> {{'count'|translate}}:
          <span>{{totalFltRecords}}</span>
        </div>

        <div class="actions btn-cal" fxLayout="row" fxLayoutAlign="end center"
          [ngClass]="{'trade-view-header': (isinputui() && isRapid)}">

          <button *ngIf="isNewBtn && getNewFunctionName" class="create" matTooltip="{{'create'|translate}}"
            matTooltipPosition="above" type="button" mat-mini-fab color="primary" [routerLink]="routeUrl?.create">
            <mat-icon>add</mat-icon>
          </button>
          <button *ngIf="isPostPending && getEditFunctionName" class="post-btn"
            matTooltip="{{'post_pending'|translate}}" matTooltipPosition="above" [disabled]="!selectedRows.length"
            mat-menu-item (click)="postPending()">
            <span class="ui-text"> {{'post_pending'|translate}}</span>
          </button>
        
          <button          
          *ngIf="componentName =='emailLogDashboard'"  [disabled]="(selectedRows && selectedRows.length==0)"  style="cursor: pointer;"
          matTooltip="{{'download_selected'|translate}}" class="post-btn"  matTooltipPosition="above" (click)="downloadAttachments()">
          <span class="ui-text"> {{'download_selected'|translate}}</span>
        </button>

          <span *ngIf="isExportBtn">
            <button
              *ngIf="!filteredData.length && (componentName =='runViewOpenTaxlots' || componentName =='viewclosedtaxLots' || componentName =='viewtotalgl'|| componentName =='rapidViewtotalgl')"
              [disabled]="!totalRecords || isFryFilterAct" class="export" type="button" mat-mini-fab color="primary"
              matTooltip="Export" matTooltipPosition="above" (click)="downloadCSV()">
              <mat-icon>file_download</mat-icon>
            </button>
            <button
              *ngIf="filteredData.length && (componentName =='runViewOpenTaxlots' || componentName =='viewclosedtaxLots' || componentName =='viewtotalgl'|| componentName =='rapidViewtotalgl')"
              class="export" type="button" mat-mini-fab color="primary" matTooltip="Export (Filter)"
              matTooltipPosition="above" (click)="getDownloadCsvFilteredData()">
              <mat-icon>file_download</mat-icon>
            </button>
          </span>
          <button class="create" matTooltip="{{'click_here_edit'|translate}}" matTooltipPosition="above" type="button"
            mat-mini-fab color="primary" (click)="displayForm= !displayForm;setExpendView()">
            <mat-icon>filter_list</mat-icon>
          </button>
          <!-- <button
            *ngIf="componentName !='runViewOpenTaxlots' && componentName !='viewclosedtaxLots' && componentName !='viewtotalgl' && componentName !='rapidViewtotalgl'"
            class="options icon" [matMenuTriggerFor]="mailOptions" mat-icon-button
            style="box-shadow: none; background: none; color:#1d2125;">
            <mat-icon>more_vert</mat-icon>
          </button> -->

          <div
            *ngIf="componentName !='runViewOpenTaxlots' && componentName !='viewclosedtaxLots' && componentName !='viewtotalgl' && componentName !='rapidViewtotalgl'">

            <button *ngIf="isUploadBtn && getuploadFunctionName" mat-mini-fab color="primary"
              matTooltip=" {{'upload'|translate}}" matTooltipPosition="above" [routerLink]="routeUrl['upload']">
              <mat-icon>file_upload</mat-icon>
            </button>

            <button *ngIf="isExportBtn && (componentName != 'viewDataCollection')" mat-mini-fab color="primary"
              matTooltip=" {{'export'|translate}}" matTooltipPosition="above" [matMenuTriggerFor]="menuExport">
              <mat-icon>file_download</mat-icon>
            </button>
            <button *ngIf="isExportBtn && (componentName == 'viewDataCollection')"
              [disabled]="!totalFltRecords || isFryFilterAct" mat-mini-fab color="primary"
              matTooltip=" {{'export'|translate}}" matTooltipPosition="above" [matMenuTriggerFor]="menuExport"
              (click)="downloaddatasetData()">
              <mat-icon>file_download</mat-icon>
            </button>
            <button
              *ngIf="selectedRows.length && (componentName == 'tradeList' || (componentName ==  'rapidTradeList' && getdeleteButtonFunctionName))"
              [disabled]="transferTradeHave" mat-mini-fab color="primary" matTooltip=" {{'delete_selected'|translate}}"
              matTooltipPosition="above" (click)="deleteModal('deleteSelected')">
              <mat-icon>delete</mat-icon>
            </button>
            <button *ngIf="selectedRows.length &&(componentName ==  'corporateAction' && getdeleteButtonFunctionName)"
              mat-mini-fab color="primary" matTooltip=" {{'delete_selected'|translate}}" matTooltipPosition="above"
              (click)="deleteModal('deleteSelected')">
              <mat-icon>delete</mat-icon>
            </button>

            <ng-container
              *ngIf="(componentName != 'jeThirdParty' && componentName != 'RapidJournalentry' && componentName !='corporateAction') && getdeleteButtonFunctionName && (selectedRows.length|| filteredData.length)">
              <button *ngIf="(isDeleteBtn && (totalRecords || totalFltRecords || selectedRows.length ))" mat-mini-fab
                color="primary" matTooltip=" {{'delete'|translate}}" matTooltipPosition="above"
                [matMenuTriggerFor]="menuDelete">
                <mat-icon>delete</mat-icon>
              </button>
            </ng-container>
            <ng-container
            *ngIf="componentName == 'RapidJournalentry' && JournalEnt  && getdeleteButtonFunctionName && (selectedRows.length|| filteredData.length)">
            <button *ngIf="(isDeleteBtn && (totalRecords || totalFltRecords || selectedRows.length ))" mat-mini-fab
              color="primary" matTooltip=" {{'delete'|translate}}" matTooltipPosition="above"
              [matMenuTriggerFor]="menuDeleteJE">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
          </div>

          <mat-menu #menuExport="matMenu">
            <button mat-menu-item
              *ngIf="!filteredData.length &&  (componentName != 'viewDataCollection' && componentName != 'reBudgets'  && componentName != 'rapidReBudgets' && componentName != 'disableAutoUpdateViaAllocationUpdate' && componentName != 'redemptionActivityUpdate' && componentName != 'subscriptionActivityUpdate' && componentName != 'globalMarketDataUpdateList')"
              [disabled]="!totalRecords || isFryFilterAct" (click)="downloadCSV()">
              <span *ngIf="!filteredData.length && (componentName == 'fetchCsdBankTransactionPendingReview')">
                {{'download_as_excel'|translate}}</span>

              <span *ngIf="!filteredData.length && (componentName != 'fetchCsdBankTransactionPendingReview')">
                {{'download_as_csv'|translate}}
              </span>

            </button>
            <button mat-menu-item
              *ngIf="!filteredData.length && ( componentName == 'disableAutoUpdateViaAllocationUpdate' || componentName == 'redemptionActivityUpdate' || componentName == 'subscriptionActivityUpdate')"
              [disabled]="!totalRecords || isFryFilterAct" (click)="downloadExcel()">
              <span> {{'download_as_excel'|translate}}</span>
            </button>
            <button mat-menu-item
              *ngIf="filteredData.length && (componentName != 'viewDataCollection' && componentName != 'disableAutoUpdateViaAllocationUpdate' && componentName != 'redemptionActivityUpdate' && componentName != 'subscriptionActivityUpdate' && componentName != 'globalMarketDataUpdateList')"
              (click)="getDownloadCsvFilteredData($event)">
              <span *ngIf="filteredData.length && (componentName == 'fetchCsdBankTransactionPendingReview')">
                {{'download_as_excel_filtered'|translate}}</span>
              <span *ngIf="filteredData.length && (componentName != 'fetchCsdBankTransactionPendingReview')">
                {{'download_as_csv_filtered'|translate}}
              </span>
            </button>

            <button mat-menu-item
              *ngIf="filteredData.length && ( componentName == 'disableAutoUpdateViaAllocationUpdate' || componentName == 'redemptionActivityUpdate' || componentName == 'subscriptionActivityUpdate' || componentName == 'globalMarketDataUpdateList')"
              (click)="getdownloadExcelFilteredData($event)">
              <span> {{'download_as_excel_filtered'|translate}}</span>
            </button>


          </mat-menu>
          <button *ngIf="filteredData.length && (componentName == 'dataMonitoring' || componentName == 'dataMonitoringinput')" mat-mini-fab color="primary"
          matTooltip=" {{'export'|translate}}"  (click)="dataDownload($event)" matTooltipPosition="above">
          <mat-icon>file_download</mat-icon>
        </button>
          <mat-menu #menuDelete="matMenu"> 
            <!-- <button mat-menu-item
              *ngIf=" !filteredData.length &&(componentName != 'tradeList' && componentName !=  'rapidTradeList' && componentName != 'seamlessmarketdata' && componentName !=  'RapidSeamlessmarketdata'  && componentName !=  'journalentry'&& componentName !='RapidJournalentry'&& componentName !=  'reBudgets' && componentName !=  'rapidReBudgets') "
              (click)="deleteModal('deleteAll')">{{'delete_all'|translate}}</button> -->

            <button mat-menu-item *ngIf="selectedRows.length && componentName !='RapidJournalentry'"
              (click)="deleteModal('deleteSelected')">{{'delete_selected'|translate}}</button>
            <!-- <button mat-menu-item *ngIf="componentName == 'tradeList'" (click)="deleteByCriteriaModal()"> {{'delete_by_criteria'|translate}}</button> -->
            <button mat-menu-item
              *ngIf="filteredData.length && (componentName == 'journalentry' || componentName =='nonTradeActivity' || componentName != 'seamlessmarketdata' || componentName !=  'RapidSeamlessmarketdata'  || componentName =='rapidNonTradeActivity'|| componentName =='fetchBankTransaction' || componentName =='rapidNonTradeActivity' || componentName ==  'reBudgets' || componentName ==  'rapidReBudgets')"
              (click)="deleteModal('deleteFiltered')"> {{'delete_filter'|translate}}
            </button>
          </mat-menu>
          <mat-menu #menuDeleteJE="matMenu">
            <button mat-menu-item *ngIf="selectedRows.length"
              (click)="deleteModal('deleteSelected')">{{'delete_selected'|translate}}</button>
              <button mat-menu-item
              *ngIf="filteredData.length && Journalcount"
              (click)="deleteModal('deleteFiltered')"> {{'delete_filter'|translate}}
            </button>
          </mat-menu>
        </div>
      </div>
      <ag-grid-angular [rowHeight]="rowHeight" class="ag-theme-alpine setGridHeight" [gridOptions]="gridOptions"
        [enableRangeSelection]="true" [enableCharts]="true" [columnDefs]="columnDefs" [rowData]="rowData"
        [statusBar]="statusBar" [enableCellTextSelection]="true" [isRowSelectable]="isRowSelectable"
        [defaultColDef]="defaultColDef" [sideBar]="sideBar" [groupSelectsChildren]="groupSelectsChildren"
        [frameworkComponents]="frameworkComponents" [suppressRowClickSelection]="true" [animateRows]="true"
        (filterModified)="getFilterData($event)" (filterChanged)="getFilteredData($event)" [rowSelection]="'multiple'"
        [pagination]="true" [paginationPageSize]="paginationPageSize" (selectionChanged)='onSelectionChanged($event)'
        [cacheBlockSize]="cacheBlockSize" [suppressPaginationPanel]="true" (gridReady)="onGridReady($event)"
        (mouseleave)="mouseOutOfGrid($event)" toolPanelSuppressGroups suppressRowClickSelection
        (rowEditingStarted)="onRowEditingStarted($event)" (rowEditingStopped)="onRowEditingStopped($event)"
        (cellClicked)="onCellClicked($event)" editType="fullRow" [suppressClickEdit]="true" [modules]="modules"
        (cellValueChanged)="onCellValueChanged($event)" [masterDetail]="isshowmasterDetail"
        [detailCellRenderer]="detailCellRenderer" [detailCellRendererParams]="detailCellRendererParams"
        [suppressColumnVirtualisation]="true">
      </ag-grid-angular>

      <div class="iconGroup" *ngIf="rowData">
        <div class="pi pi-step-backward customIcon" (click)="pageShift('onBtFirst')" matTooltip="First"></div>
        <div class="pi pi-caret-left customIcon" (click)="pageShift('onBtPrevious')" matTooltip="Previous"></div>
        <div class="paginationInput"><input type="number" oninput="value = Math.abs(value)" class="form-control"
            matTooltip="Enter pageNumber." (change)="pageShift('selectedPagePagibation',pageNumber)"
            [(ngModel)]="pageNumber" min="1" [max]="totalPages" required> <span><abbr>/</abbr> {{ totalPages
            }}</span></div>
        <div class="pi pi-caret-right customIcon" (click)="pageShift('onBtNext')" matTooltip="Next"></div>
        <div class="pi pi-step-forward customIcon" (click)="pageShift('onBtLast')" matTooltip="Last"></div>

      </div>


    </fury-list>



    <!-- <div fxLayout="column" fxLayoutAlign="start center">
      <div class="card cardw100">
        <ag-grid-angular class="ag-theme-alpine setGridHeight" [gridOptions]="gridOptions" [enableRangeSelection]="true"
          [enableCharts]="true" [columnDefs]="columnDefs"
          [rowData]="rowData" [statusBar]="statusBar" [defaultColDef]="defaultColDef" [sideBar]="sideBar"
          [groupSelectsChildren]="false" [suppressRowClickSelection]="true" [animateRows]="true" (filterChanged)="Search($event,'gridfilter')"
          [rowSelection]="'multiple'" [pagination]="true" [paginationPageSize]="paginationPageSize"
          [cacheBlockSize]="cacheBlockSize" [suppressPaginationPanel]="true" (gridReady)="onGridReady($event)"
          toolPanelSuppressGroups suppressRowClickSelection>
        </ag-grid-angular>
      </div>
    </div> -->
  </fury-page-layout-content>

</fury-page-layout>

<p-dialog [modal]="true" class="delModal" header="{{modalHeading}}" [(visible)]="display" [style]="{width: '0vw'}"
  [baseZIndex]="10000">
  <div *ngIf="(componentName == 'tradeList' || componentName ==  'rapidTradeList')" class="row delCriteria">
    <mat-form-field appearance="legacy" class="col-md-5 margindropdown">
      <mat-label appRemoveAriaOwns class="label-font">Broker Account</mat-label>
      <mat-select [(ngModel)]="selectedBrokerDelete" (selectionChange)="getTickerList(selectedBrokerDelete.name)"
        class="form-control">
        <mat-option value="" disabled>Select Option</mat-option>
        <mat-option *ngFor="let element of custodianList" [value]="element">
          {{element.name|translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="legacy" class="col-md-5">
      <mat-label appRemoveAriaOwns class="label-font">Ticker</mat-label>
      <mat-select [(ngModel)]="selectedTicker" class="form-control">
        <mat-option value="" disabled>Select Option</mat-option>
        <mat-option *ngFor="let element of tickerList" [value]="element">
          {{element.name|translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <p-footer>
    <button *ngIf="(componentName == 'tradeList'|| componentName ==  'rapidTradeList')" pButton type="button" label="OK"
      style="margin: 0px 2px 0px 0px;" (click)="deleteModal('deleteByCriteria')"
      [disabled]="!selectedBrokerDelete"></button>

    <button *ngIf="(componentName == 'tradeList' || componentName ==  'rapidTradeList')" pButton type="button"
      label="Cancel" style="margin: 0px 2px 0px 0px;" (click)="display=false"></button>



  </p-footer>

</p-dialog>
<p-dialog [modal]="true" class="delModal" header="{{'details_comments'|translate}}" [(visible)]="iscommentpopup" [style]="{width: '60vw'}"
[baseZIndex]="10000">
<div class="modal-popup">
  <div class="modal-body">
    <div>
      <span>
        <b>
          {{commentpopup}}
        </b>
      </span>
    </div>
  </div>
</div>
<p-footer>
  <button class="btn-logout" (click)="hideDialogBox()"> {{'ok'|translate}} </button>
</p-footer>
</p-dialog>
/*******************************************************************************
 * FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
 *  __________________
 *
 *  2017 - 2018 FORMIDIUM Pvt. Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Pvt. Ltd
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
 * by visitng www.formidium.com.
 ******************************************************************************/
import { Component } from "@angular/core";
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from "ag-grid-community";
import moment from 'moment';

@Component({
    selector: "edit-cell-number-editor",
    templateUrl: './edit-cell-number-editor.component.html',
    styleUrls: ['./edit-cell-number-editor.component.scss']
})
export class EditCellNumberEditor implements ICellEditorAngularComp {
    private params!: ICellEditorParams;
    public selectedData = null;
    public required: boolean = false;
    public isDisabled: boolean = false;
    public formData: any;
    public columnDef: any;
    public editableRowData: any;
    componentName: any;

    agInit(params: ICellEditorParams): void {
        this.params = params;
        let colDef = this.params.colDef;
        this.columnDef = this.params.colDef;
        this.required = colDef["required"];
        this.editableRowData = this.params.data;
        let value = params.value;
        this.selectedData = value;
        this.componentName = this.params.api['componentName'];
        if (this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
            this.formData = this.params.api['caFormData'];
            this.getDisabledFlag();
        }
    }

    getValue = () => {
        let dataString = null;
        if (this.params.node.data.isEditingSave && this.selectedData) {
            dataString = this.selectedData;
        } else if (this.componentName == 'globalMarketDataUpdateList' && this.params.node.data.isEditingSave) {
            dataString = 0;
        } else {
            dataString = this.params.value;
        }
        return dataString;
    };

    afterGuiAttached = () => {
        if (!this.params.value) {
            return;
        }
        this.selectedData = this.params.value;
    };

    refresh(params?: ICellEditorParams): boolean {
        this.params = params;
        this.selectedData = params.value;
        return true;
    }

    onModelChange(value: any) {
        if (this.componentName == 'globalMarketDataUpdateList') {
            this.selectedData = value === null ? 0 : value;
        }
    }


    getDisabledFlag() {
        if (this.formData) {
            const transactionType = this.formData?.transactionType?.id || '';
            const selectedNodeCsdEntId = this.editableRowData?.['csdEnterpriseId'] || '';
            const isCsdIntegrated = this.editableRowData?.['csdIntegrated'] || '';

            if (transactionType == 'Redemption - Partial by Amount' || transactionType == 'Redemption - Transfer') {
                if (selectedNodeCsdEntId && isCsdIntegrated) {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount" || this.columnDef.field == "shares" || this.columnDef.field == "perValueOfAc")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    }
                } else {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        if (this.columnDef && (this.columnDef.field == "shares" || this.columnDef.field == "perValueOfAc" || this.columnDef.field == "amount")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef.field == "amount") {
                            this.isDisabled = true;
                        }
                        else {
                            this.isDisabled = false;
                        }
                    }
                }
            }
            else if (transactionType == 'Redemption - Full') {
                if (selectedNodeCsdEntId && isCsdIntegrated) {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount" || this.columnDef.field == "shares" || this.columnDef.field == "perValueOfAc")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "perValueOfAc")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    }
                } else {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        this.amountSharePerValueAcDisable();
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "perValueOfAc")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    }
                }
            }
            else if (transactionType == 'Redemption - Partial - by share or Unit') {

                if (selectedNodeCsdEntId && isCsdIntegrated) {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount" || this.columnDef.field == "shares" || this.columnDef.field == "perValueOfAc")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "shares")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    }
                } else {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        this.amountSharePerValueAcDisable();
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "shares")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    }
                }
            }
            else if (transactionType == 'Redemption - Partial - by % value of Account') {

                if (selectedNodeCsdEntId && isCsdIntegrated) {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount" || this.columnDef.field == "shares" || this.columnDef.field == "perValueOfAc")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "perValueOfAc")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    }
                } else {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        this.amountSharePerValueAcDisable();
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "perValueOfAc")) {
                            this.isDisabled = true;
                        } else {
                            this.isDisabled = false;
                        }
                    }
                }

            }
            else if (transactionType == 'Subscription - First Time' || transactionType == 'Subscription - Additional' || transactionType == 'Subscription - Transfer') {
                if (selectedNodeCsdEntId && isCsdIntegrated) {
                    if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount" || this.columnDef.field == "shares")) {
                        this.isDisabled = true;
                    }
                    else {
                        this.isDisabled = false;
                    }
                } else {
                    if (this.columnDef && (this.columnDef.field == "shares" || this.columnDef.field == "amount")) {
                        this.isDisabled = true;
                    } else {
                        this.isDisabled = false;
                    }
                }
            }
        }
    }


    amountSharePerValueAcDisable() {
        if (this.columnDef && (this.columnDef.field == "shares" || this.columnDef.field == "amount" || this.columnDef.field == "perValueOfAc")) {
            this.isDisabled = true;
        } else {
            this.isDisabled = false;
        }
    }

}


<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<div (furyClickOutside)="onClickOutside()" class="toolbar-notifications" fxFlex="grow" fxLayout="row"
  fxLayoutAlign="start center">
  <button [ngClass]="{'active':isOpen}" (click)="toggleDropdown()" [class.open]="isOpen"
    class="button toolbarsetting arrow" fxFlex="grow" mat-button type="button">

    <span class="material-icons-outlined"> settings</span> <span class="sidenavbttxt">{{'settings'|translate}}</span>
  </button>

  <div [class.open]="isOpen" class="dropdown popupvw">
    <fury-card>


      <fury-card-content class="dropdown-card-content">
        <fury-scrollbar class="dropdown-content">
          <div fxLayout="column">
            <ng-container>
              <div class="createpopup custom-setting-panel">

                <div class="popuporow bodytext">

                  <div class="col-1">
                    <div class="heading">
                      <h3>{{'lookup_tables'|translate}}</h3>
                    </div>
                    <ul>
                      <ng-container *ngFor="let item of lookupTables">
                        <li *ngIf="currentUser.authList.indexOf(item.authView) > -1">
                          <span>
                          <span *ngIf="!item.ispined" class="material-icons-outlined pushpin"
                              (click)="addToPin($event,item,'lookupTables')" matTooltip="{{'favorite'|translate}}">star</span>
                          <span class="pushset" *ngIf="item.ispined">
                            <span class="material-icons-outlined pushpin pushpinactive"
                              (click)="addToPin($event,item,'lookupTables')" matTooltip="{{'favorite'|translate}}">star</span>
                          </span>
                          <a class="text" (click)="toggleDropdown()" [class.open]="isOpen"
                            [routerLink]='[item.link]'>{{item.component_name|translate}}</a>
                          </span>                          
                          <div class="iconset">     
                            <span class="material-icons-outlined " *ngIf="item.uploadRouterlink !='' && currentUser.authList.indexOf(item.authUpload)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen" [routerLink]='[item.uploadRouterlink]'
                              matTooltip="{{'upload'|translate}}">
                              file_upload</span>                       
                            <span class=" material-icons-outlined" *ngIf="item.createRouterlink !='' && currentUser.authList.indexOf(item.authCreate)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.createRouterlink, "create"]' matTooltip="{{'create'|translate}}"> add</span>
                            <span class="material-icons-outlined" *ngIf="item.link !=''"
                              class=" material-icons-outlined" (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.link]' matTooltip="{{'view'|translate}}"> visibility</span>

                          </div>
                        </li>
                      </ng-container>
                    </ul>
                    <span *ngIf="currentUser.authList.indexOf('DIV_SETTINGS_API_MANAGEMENT') > -1 && apiManagement.length">
                      <div class="heading">
                        <h3>{{'api_management'|translate}}</h3>
                      </div>
                      <ul>
                        <ng-container *ngFor="let item of apiManagement">
                            <li>
                            <span>
                            <span class="material-icons-outlined pushpin" *ngIf="!item.ispined"
                                (click)="addToPin($event,item,'apiManagement')" matTooltip="{{'favorite'|translate}}">star</span>
                                <span class="pushset" *ngIf="item.ispined">
                                  <span class="material-icons-outlined pushpin pushpinactive"
                                    (click)="addToPin($event,item,'apiManagement')" matTooltip="{{'favorite'|translate}}">star</span>
                                </span>
                            <a class="text" (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.link]'>{{item.component_name|translate}}</a>
                            </span>
                            <div class="iconset">
                              <span class="material-icons-outlined " *ngIf="item.uploadRouterlink !='' && currentUser.authList.indexOf(item.authUpload)>-1"
                                (click)="toggleDropdown()" [class.open]="isOpen" [routerLink]='[item.uploadRouterlink]'
                                matTooltip="{{'upload'|translate}}">
                                file_upload</span>
                              <span class=" material-icons-outlined" *ngIf="item.createRouterlink !='' && currentUser.authList.indexOf(item.authCreate)>-1"
                                (click)="toggleDropdown()" [class.open]="isOpen"
                                [routerLink]='[item.createRouterlink, "create"]' matTooltip="{{'create'|translate}}"> add</span>
                              <span class="material-icons-outlined" *ngIf="item.link !='' && currentUser.authList.indexOf(item.authView)>-1"
                                class=" material-icons-outlined" (click)="toggleDropdown()" [class.open]="isOpen"
                                [routerLink]='[item.link]' matTooltip="{{'view'|translate}}"> visibility</span>
                            </div>
                          </li>
                        </ng-container>
                      </ul>
                      </span>
                  </div>
                  <div class="col-1">

                    <div class="heading">
                      <h3>{{'create_new'|translate}}</h3>
                    </div>
                    <ul>
                      <ng-container *ngFor="let item of createNew">
                        <li *ngIf="currentUser.authList.indexOf(item.authView) > -1">
                          <span>
                          <span class="material-icons-outlined pushpin" *ngIf="!item.ispined"
                              (click)="addToPin($event,item,'createNew')" matTooltip="{{'favorite'|translate}}">star</span>
                          <span class="pushset" *ngIf="item.ispined">
                            <span class="material-icons-outlined pushpin pushpinactive"
                              (click)="addToPin($event,item,'createNew')" matTooltip="{{'favorite'|translate}}">star</span>
                          </span>
                          <a class="text" (click)="toggleDropdown()" [class.open]="isOpen"
                            [routerLink]='[item.link]'>{{item.component_name|translate}}</a>
                          </span>                          
                          <div class="iconset">
                            <span *ngIf="trainingVideoCheck  && item.isVideo" class="material-icons-outlined " 
                              matTooltip="{{'help_and_resource' | translate}}" (click)="autoplay($event,item,'Create New');"> info</span>
                            <span class="material-icons-outlined " *ngIf="item.uploadRouterlink !='' && currentUser.authList.indexOf(item.authUpload)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen" [routerLink]='[item.uploadRouterlink]'
                              matTooltip="{{'upload'|translate}}">
                              file_upload</span>                            
                            <span class=" material-icons-outlined" *ngIf="item.createRouterlink !='' && currentUser.authList.indexOf(item.authCreate)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.createRouterlink, "create"]' matTooltip="{{'create'|translate}}"> add</span>                            
                            <span class="material-icons-outlined" *ngIf="item.link !=''"
                              class=" material-icons-outlined" (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.link]' matTooltip="{{'view'|translate}}"> visibility</span>
                          </div>
                        </li>
                      </ng-container>


                    </ul>
                    <div class="heading">
                      <h3>{{'trade_settings'|translate}}</h3>
                    </div>
                    <ul>
                      <ng-container *ngFor="let item of tradeSetting">
                        <li *ngIf="currentUser.authList.indexOf(item.authView) > -1">
                          <span>
                          <span class="material-icons-outlined pushpin" *ngIf="!item.ispined"
                              (click)="addToPin($event,item,'tradeSetting')" matTooltip="{{'favorite'|translate}}">star</span>
                              <span class="pushset" *ngIf="item.ispined">
                                <span class="material-icons-outlined pushpin pushpinactive"
                                  (click)="addToPin($event,item,'tradeSetting')" matTooltip="{{'favorite'|translate}}">star</span>
                              </span>
                          <a class="text" (click)="toggleDropdown()" [class.open]="isOpen"
                            [routerLink]='[item.link]'>{{item.component_name|translate}}</a>
                          </span>                          
                          <div class="iconset">
                          <span *ngIf="trainingVideoCheck  && item.isVideo" class="material-icons-outlined " 
                              matTooltip="{{'help_and_resource' | translate}}" (click)="autoplay($event,item,'Trade Settings');"> info</span>
                            <span class="material-icons-outlined " *ngIf="item.uploadRouterlink !='' && currentUser.authList.indexOf(item.authUpload)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen" [routerLink]='[item.uploadRouterlink]'
                              matTooltip="{{'upload'|translate}}">
                              file_upload</span>
                            <span class=" material-icons-outlined" *ngIf="item.createRouterlink !='' && currentUser.authList.indexOf(item.authCreate)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.createRouterlink, "create"]' matTooltip="{{'create'|translate}}"> add</span>
                            <span class="material-icons-outlined" *ngIf="item.link !=''"
                              class=" material-icons-outlined" (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.link]' matTooltip="{{'view'|translate}}"> visibility</span>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                    <div class="heading">
                      <h3>{{'portfolio_settings'|translate}}</h3>
                    </div>
                    <ul>
                      <ng-container *ngFor="let item of portfolioSetting">
                        <li *ngIf="currentUser.authList.indexOf(item.authView) > -1">
                          <span>
                          <span class="material-icons-outlined pushpin" *ngIf="!item.ispined"
                              (click)="addToPin($event,item,'portfolioSetting')" matTooltip="{{'favorite'|translate}}">star</span>
                          <span class="pushset" *ngIf="item.ispined">
                            <span class="material-icons-outlined pushpin pushpinactive"
                              (click)="addToPin($event,item,'portfolioSetting')" matTooltip="{{'favorite'|translate}}">star</span>
                          </span>
                          <a class="text" (click)="toggleDropdown()" [class.open]="isOpen"
                            [routerLink]='[item.link]'>{{item.component_name|translate}}</a>
                          </span>                          
                          <div class="iconset">   
                            <span class="material-icons-outlined " *ngIf="item.uploadRouterlink !='' && currentUser.authList.indexOf(item.authUpload)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen" [routerLink]='[item.uploadRouterlink]'
                              matTooltip="{{'upload'|translate}}">
                              file_upload</span>                         
                            <span class=" material-icons-outlined" *ngIf="item.createRouterlink !='' && currentUser.authList.indexOf(item.authCreate)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.createRouterlink, "create"]' matTooltip="{{'create'|translate}}"> add</span>
                            <span class="material-icons-outlined" *ngIf="item.link !=''"
                              class=" material-icons-outlined" (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.link]' matTooltip="{{'view'|translate}}"> visibility</span>

                          </div>
                        </li>
                      </ng-container>
                    </ul>


                  </div>
                  <div class="col-1">
                    <div class="heading">
                      <h3>{{'accounting_settings'|translate}}</h3>
                    </div>
                    <ul>
                      <ng-container *ngFor="let item of accountingSetting">
                        <li *ngIf="currentUser.authList.indexOf(item.authView) > -1">
                          <span>
                          <span class="material-icons-outlined pushpin" *ngIf="!item.ispined"
                          (click)="addToPin($event,item,'accountingSetting')" matTooltip="{{'favorite'|translate}}">star</span>
                          <span class="pushset" *ngIf="item.ispined">
                            <span class="material-icons-outlined pushpin pushpinactive"
                              (click)="addToPin($event,item,'accountingSetting')"
                              matTooltip="{{'favorite'|translate}}">star</span>
                          </span>
                          <a class="text" style="text-transform: initial;" (click)="toggleDropdown()" [class.open]="isOpen"
                            [routerLink]='[item.link]'>{{item.component_name|translate}}</a> 
                          </span>                         
                          <div class="iconset"> 
                            <span *ngIf="trainingVideoCheck  && item.isVideo" class="material-icons-outlined " (click)="autoplay($event,item,'Accounting Settings');"
                              matTooltip="{{'help_and_resource' | translate}}"> info</span>   
                            <span class="material-icons-outlined " *ngIf="item.uploadRouterlink !='' && currentUser.authList.indexOf(item.authUpload)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen" [routerLink]='[item.uploadRouterlink]'
                              matTooltip="{{'upload'|translate}}">
                              file_upload</span>                       
                            <span class=" material-icons-outlined" *ngIf="item.createRouterlink !='' && currentUser.authList.indexOf(item.authCreate)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.createRouterlink, "create"]' matTooltip="{{'create'|translate}}"> add</span>
                            <span class="material-icons-outlined" *ngIf="item.link !=''"
                              class=" material-icons-outlined" (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.link]' matTooltip="{{'view'|translate}}"> visibility</span>

                          </div>
                        </li>
                      </ng-container>
                    </ul>

                  </div>
                  <div class="col-1">

                    <div class="heading">
                      <h3>{{'investor_settings'|translate}}</h3>
                    </div>
                    <ul>
                      <ng-container *ngFor="let item of userInvestorSetting">
                        <li *ngIf="currentUser.authList.indexOf(item.authView) > -1">
                          <span>
                          <span class="material-icons-outlined pushpin" *ngIf="!item.ispined"
                              (click)="addToPin($event,item,'userInvestorSetting')" matTooltip="{{'favorite'|translate}}">star</span>
                              <span class="pushset" *ngIf="item.ispined">
                                <span class="material-icons-outlined pushpin pushpinactive"
                                  (click)="addToPin($event,item,'userInvestorSetting')" matTooltip="{{'favorite'|translate}}">star</span>
                              </span>
                          <a class="text" (click)="toggleDropdown()" [class.open]="isOpen"
                            [routerLink]='[item.link]'>{{item.component_name|translate}}</a>
                          </span>
                          <div class="iconset">
                            <span *ngIf="trainingVideoCheck  && item.isVideo" class="material-icons-outlined " 
                              matTooltip="{{'help_and_resource' | translate}}"  (click)="autoplay($event,item,'Investor Settings');"> info</span>
                            <span class="material-icons-outlined " *ngIf="item.uploadRouterlink !='' && currentUser.authList.indexOf(item.authUpload)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen" [routerLink]='[item.uploadRouterlink]'
                              matTooltip="{{'upload'|translate}}">
                              file_upload</span>
                            <span class=" material-icons-outlined" *ngIf="item.createRouterlink !='' && currentUser.authList.indexOf(item.authCreate)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.createRouterlink, "create"]' matTooltip="{{'create'|translate}}"> add</span>
                            <span class="material-icons-outlined" *ngIf="item.link !=''"
                              class=" material-icons-outlined" (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.link]' matTooltip="{{'view'|translate}}"> visibility</span>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                    <div class="heading">
                      <h3>{{'configuration'|translate}}</h3>
                    </div>
                    <ul>
                      <ng-container *ngFor="let item of configuration">
                        <li *ngIf="currentUser.authList.indexOf(item.authView) > -1 || currentUser.authList.indexOf(item.authUpload) > -1">
                          <span>
                          <span class="material-icons-outlined pushpin" *ngIf="!item.ispined"
                              (click)="addToPin($event,item,'configuration')" matTooltip="{{'favorite'|translate}}">star</span>
                              <span class="pushset" *ngIf="item.ispined">
                                <span class="material-icons-outlined pushpin pushpinactive"
                                  (click)="addToPin($event,item,'configuration')" matTooltip="{{'favorite'|translate}}">star</span>
                              </span>
                          <a class="text" (click)="toggleDropdown()" [class.open]="isOpen"
                            [routerLink]='[item.link]'>{{item.component_name|translate}}</a>
                          </span>
                          <div class="iconset">
                            <span *ngIf="trainingVideoCheck  && item.isVideo" class="material-icons-outlined " 
                              matTooltip="{{'help_and_resource' | translate}}" (click)="autoplay($event,item,'Configurations');"> info</span>
                            <span class="material-icons-outlined " *ngIf="item.uploadRouterlink !='' && currentUser.authList.indexOf(item.authUpload)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen" [routerLink]='[item.uploadRouterlink]'
                              matTooltip="{{'upload'|translate}}">
                              file_upload</span>
                            <span class=" material-icons-outlined" *ngIf="item.createRouterlink !='' && currentUser.authList.indexOf(item.authCreate)>-1"
                              (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.createRouterlink, "create"]' matTooltip="{{'create'|translate}}"> add</span>                           
                            <span class="material-icons-outlined" *ngIf="item.link !='' && currentUser.authList.indexOf(item.authView)>-1"
                              class=" material-icons-outlined" (click)="toggleDropdown()" [class.open]="isOpen"
                              [routerLink]='[item.link]' matTooltip="{{'view'|translate}}"> visibility</span>

                          </div>
                        </li>
                      </ng-container>
                    </ul>
                    <div class="heading" *ngIf="currentUser.authList.indexOf('SEAMLESS_PDF_MERGE_IN_SEQUENCE')">
                      <h3>{{'toolbar'|translate}}</h3>
                    </div>
                    <ul>
                      <ng-container *ngFor="let item of toolbarSetting">
                        <li *ngIf="currentUser.authList.indexOf(item.authView) > -1 || currentUser.authList.indexOf(item.authUpload) > -1">
                          <span>
                          <span class="material-icons-outlined pushpin" *ngIf="!item.ispined"
                              (click)="addToPin($event,item,'configuration')" matTooltip="{{'favorite'|translate}}">star</span>
                              <span class="pushset" *ngIf="item.ispined">
                                <span class="material-icons-outlined pushpin pushpinactive"
                                  (click)="addToPin($event,item,'configuration')" matTooltip="{{'favorite'|translate}}">star</span>
                              </span>
                          <a class="text" (click)="toggleDropdown();openPdfMerbe()" [class.open]="isOpen"
                            >{{item.component_name|translate}}</a>
                          </span>
                          <div class="iconset">                        
                                                    
                            <span class="material-icons-outlined" *ngIf="currentUser.authList.indexOf(item.authView)>-1"
                              class=" material-icons-outlined" (click)="toggleDropdown();openPdfMerbe()" [class.open]="isOpen"
                               matTooltip="{{'view'|translate}}"> visibility</span>

                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </div>

                </div>


              </div>

            </ng-container>
          </div>

        </fury-scrollbar>
      </fury-card-content>



    </fury-card>
  </div>
</div>
<p-dialog [modal]="true" header="{{'merge_pdf'|translate}}" class="isdModal Dialog_width_Large Oms_button"
[(visible)]="displayPdfMerge" [baseZIndex]="10000">
<fury-merge-pdf *ngIf="displayPdfMerge"></fury-merge-pdf>
</p-dialog>